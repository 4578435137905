.sketch-picker {
  margin-top: 20px;
  box-shadow: none !important;
  padding: 0 !important;
  width: 100% !important;
}

.sketch-picker div,
.sketch-picker input,
.sketch-picker label {
  font-family: 'Montserrat' !important;
}

.sketch-picker > div:nth-child(1) {
  border-radius: 20px;
  padding: 0 !important;
  width: 100%;
  height: 134px;
}

.sketch-picker > div:nth-child(2) {
  margin: 10px 0;
}

.sketch-picker > div:nth-child(3) div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sketch-picker > div:nth-child(2) div:nth-child(1) {
  border-radius: 20px;
}

.sketch-picker > div:nth-child(2) div:nth-child(2) {
  border-radius: 20px;
}

.sketch-picker > div:nth-child(2) > div:nth-child(2) {
  display: none;
}

.sketch-picker > div:nth-child(3) > div:nth-child(1) > div > input {
  border: none !important;
  height: 34px;
  border-radius: 7px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #1a2032;
}

.sketch-picker > div:nth-child(3) > div:nth-child(1) > div > label {
  text-align: center !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px !important;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #1a2032 !important;
  margin: 8px auto;
}

.sketch-picker > div:nth-child(3) > div:nth-child(2) > div > input {
  width: 45px !important;
  height: 34px;
  background: #ffffff;
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 7px;
  text-align: center;

  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
}

.sketch-picker > div:nth-child(3) > div:nth-child(2) > div > label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #adadad !important;
  margin: 8px auto;
  text-align: center !important;
}

.sketch-picker > div:nth-child(3) > div:nth-child(3) > div > input {
  width: 45px !important;
  height: 34px;
  background: #ffffff;
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 7px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
}

.sketch-picker > div:nth-child(3) > div:nth-child(3) > div > label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #adadad !important;
  margin: 8px auto;
  text-align: center !important;
}

.sketch-picker > div:nth-child(3) > div:nth-child(4) > div > input {
  width: 45px !important;
  height: 34px;
  background: #ffffff;
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 7px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
}

.sketch-picker > div:nth-child(3) > div:nth-child(4) > div > label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #adadad !important;
  margin: 8px auto 15px auto;
  text-align: center !important;
}

.sketch-picker > div:nth-child(3) > div:nth-child(5) > div > input {
  width: 45px !important;
  height: 34px;
  background: #ffffff;
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 7px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #999999 !important;
}

.sketch-picker > div:nth-child(3) > div:nth-child(5) > div > label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #adadad !important;
  margin: 8px auto;
  text-align: center !important;
}

.sketch-picker > div:nth-child(4) div {
  width: 36px !important;
  height: 36px !important;
  border-radius: 8px !important;
  margin: 0 10px 8px 0 !important;
}
