/* for percentage bar */
.percentage-range-bar::-webkit-slider-thumb {
  background-color: #fff;
  border: 2.5px solid #7b61ff;
  border-radius: 100%;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  -webkit-appearance: none;
}

.percentage-range-bar::-moz-range-thumb {
  background-color: #fff;
  border: 2.5px solid #7b61ff;
  border-radius: 100%;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  -webkit-appearance: none;
}
