@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);
/* font-family: 'Spoqa Han Sans Neo'; */

@import url('https://fonts.googleapis.com/earlyaccess/nanumgothic.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* font-family: 'Montserrat', sans-serif; */

[class*='__icon'] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.img__list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.img__list::-webkit-scrollbar {
  display: none !important;
}

.img__list .edit-new {
  position: sticky;
  bottom: 20px;
}
.img__list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.wrap .gnb__home__icon {
  width: 22px;
  height: 22px;
  background-image: url('../images/icons/side-gnb/ico-home-black.svg');
}
.studio__side .gnb__home__icon {
  width: 22px;
  height: 22px;
  background-image: url('../images/icons/side-gnb/ico-home-white.svg');
}

.wrap .gnb__project__icon {
  width: 24px;
  height: 24px;
  background-image: url('../images/icons/side-gnb/ico-project-black.svg');
}
.studio__side .gnb__project__icon {
  width: 24px;
  height: 24px;
  background-image: url('../images/icons/side-gnb/ico-project-white.svg');
}

.wrap .gnb__metahuman__icon {
  width: 22px;
  height: 22px;
  background-image: url('../images/icons/side-gnb/ico-metahuman-black.svg');
}
.studio__side .gnb__metahuman__icon {
  width: 22px;
  height: 22px;
  background-image: url('../images/icons/side-gnb/ico-metahuman-white.svg');
}

.wrap .gnb__file__icon {
  width: 22px;
  height: 22px;
  background-image: url('../images/icons/side-gnb/ico-file-black.svg');
}
.studio__side .gnb__file__icon {
  width: 22px;
  height: 22px;
  background-image: url('../images/icons/side-gnb/ico-file-white.svg');
}

.profile__cash__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-coin-gold.svg');
}

.cash__gray__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-coin-gray.svg');
}

.wrap .membership__thunderbolt__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/common/ico-thunderbolt-black.svg');
}
.studio__side .membership__thunderbolt__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/common/ico-thunderbolt-white.svg');
}

.project__plus__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/common/ico-plus-white.svg');
}

.style__metahuman__icon {
  width: 28px;
  height: 28px;
  background-image: url('../images/icons/studio-side/ico-metahuman-gray.svg');
  background-size: 28px 28px;
}
.style__tab button.active .style__metahuman__icon {
  background-image: url('../images/icons/studio-side/ico-metahuman-purple.svg');
}

.style__background__icon {
  width: 28px;
  height: 28px;
  background-image: url('../images/icons/studio-side/ico-background-gray.svg');
  background-size: 28px 28px;
}
.style__tab button.active .style__background__icon {
  background-image: url('../images/icons/studio-side/ico-background-purple.svg');
}

.deco .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.deco .overlay:after {
  position: absolute;
  display: block;
  content: '';
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-bottom: none;
  z-index: 101;
  animation: rotate infinite 2s linear;
}
@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.style__deco__icon {
  width: 28px;
  height: 28px;
  background-image: url('../images/icons/studio-side/ico-deco-gray.svg');
  background-size: 28px 28px;
}
.style__tab button.active .style__deco__icon {
  background-image: url('../images/icons/studio-side/ico-deco-purple.svg');
}

.style__text__icon {
  width: 28px;
  height: 28px;
  background-image: url('../images/icons/studio-side/ico-text-gray.svg');
  background-size: 28px 28px;
}
.style__tab button.active .style__text__icon {
  background-image: url('../images/icons/studio-side/ico-text-purple.svg');
}

.style__transition__icon {
  width: 28px;
  height: 28px;
  background-image: url('../images/icons/studio-side/ico-transition-gray.svg');
  background-size: 28px 28px;
}
.style__tab button.active .style__transition__icon {
  background-image: url('../images/icons/studio-side/ico-transition-purple.svg');
}

.style__translate__icon {
  width: 28px;
  height: 28px;
  background-size: 28px 28px;
  background-image: url('../images/icons/studio-side/ico-translate-gray.svg');
}
.style__tab button.active .style__translate__icon {
  background-image: url('../images/icons/studio-side/ico-translate-purple.svg');
}

.studio__backArrow__icon {
  width: 11px;
  height: 11px;
  background-image: url('../images/icons/common/ico-backArrow-black.svg');
}

.studio__pencil__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/common/ico-pencil-gray.svg');
}

.studio__pencil__icon:hover .ballon__tool {
  display: block;
}

.studio__pencil__icon .ballon__tool {
  display: none;
  position: absolute;
  width: 64px;
  height: 32px;
  bottom: 23px;
  left: 196px;
  text-align: center;
  /* padding: 6px 12px; */
  background: #333a43;
  border-radius: 4px;
  z-index: 99;
}

.studio__pencil__icon .ballon__tool::after {
  border-top: 6px solid transparent;
  border-left: 0px solid #333a43;
  border-right: 10px solid #333a43;
  border-bottom: 6px solid transparent;
  content: '';
  position: absolute;
  top: 8px;
  left: -9px;
}

.studio__pencil__icon .ballon__tool span {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.studio__save__icon {
  background-image: url('../images/icons/common/ico-save-black.svg');
  background-size: 20px 20px;
}

.studio__export__icon {
  background-image: url('../images/icons/common/ico-export-white.svg');
  background-size: 20px 20px;
}

.studio__voice__icon {
  background-image: url('../images/icons/common/ico-voice-white.svg');
  background-size: 20px 20px;
}

.timeline__prev__icon {
  background-image: url('../images/icons/studio-timeline/prev-arrow-black.svg');
  background-size: 18px 18px;
}

.timeline__next__icon {
  background-image: url('../images/icons/studio-timeline/next-arrow-black.svg');
  background-size: 18px 18px;
}

.timeline__cut__icon {
  background-image: url('../images/icons/studio-timeline/cut-black.svg');
  background-size: 20px 20px;
}

.timeline__trash__icon {
  background-image: url('../images/icons/studio-timeline/trash-black.svg');
  background-size: 20px 20px;
}

.timeline__copy__icon {
  background-image: url('../images/icons/studio-timeline/copy-black.svg');
  background-size: 20px 20px;
}

.timeline__zoomOut__icon {
  background-image: url('../images/icons/studio-timeline/zoomOut-black.svg');
  background-size: 14px 14px;
}

.timeline__zoomIn__icon {
  background-image: url('../images/icons/studio-timeline/zoomIn-black.svg');
  background-size: 14px 14px;
}

.timeline__fullScreen__icon {
  width: 17.5px;
  height: 12.5px;
  background-image: url('../images/icons/studio-timeline/fullScreen-black.svg');
}

.timeline__metahuman__icon {
  width: 18px;
  height: 17.4px;
  background-image: url('../images/icons/studio-timeline/metahuman-black.svg');
}
.timeline__text__icon {
  width: 13px;
  height: 13px;
  background-image: url('../images/icons/studio-timeline/text-white.png');
}

.timeline__clip__icon {
  width: 18px;
  height: 23px;
  background-image: url('../images/icons/studio-timeline/clip-black.svg');
}
.timeline__background__icon {
  width: 20px;
  height: 23px;
  background-image: url('../images/icons/studio-timeline/background-black.svg');
}

.timeline__img__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/studio-timeline/img-white.svg');
}
.timeline__audio__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/studio-timeline/audio-white.svg');
}

.timeline__video__icon {
  width: 18px;
  height: 13px;
  background-image: url('../images/icons/studio-timeline/video-white.svg');
}

.timeline__soundEffect__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/studio-timeline/soundEffect-black.svg');
}

.timeline__backgroundImg__icon {
  width: 20px;
  height: 18px;
  background-image: url('../images/icons/studio-timeline/backgroundImg-white.svg');
}

.timeline__backgroundVideo__icon {
  width: 18px;
  height: 16px;
  background-image: url('../images/icons/studio-timeline/backgroundVideo-white.svg');
}

.timeline__backgroundMusic__icon {
  width: 12px;
  height: 12px;
  background-image: url('../images/icons/studio-timeline/backgroundMusic-white.svg');
}

.close__gray__icon {
  background-image: url('../images/icons/studio-side/ico-close-black.svg');
  background-size: 20px 20px;
}

.select__black__icon {
  background-image: url('../images/icons/common/ico-select-black.svg');
}

.select__purple__icon {
  background-image: url('../images/icons/common/ico-select-purple.svg');
}

.play__blue__icon {
  width: 24px;
  height: 24px;
  background-image: url('../images/icons/studio-side/ico-play-blue.svg');
}

.plus__blue__icon {
  width: 24px;
  height: 24px;
  background-image: url('../images/icons/studio-side/ico-plus-blue.svg');
}

.script__text__icon {
  background-image: url('../images/icons/studio-side/ico-scriptText-black.svg');
  background-size: 18px 18px;
}

.script__motionPreset__icon {
  background-image: url('../images/icons/studio-side/ico-motionPreset-black.svg');
  background-size: 18px 18px;
}

.profile__setting__icon {
  width: 18px;
  height: 18px;
  background-image: url('../images/icons/common/ico-setting-black.svg');
}

.profile__guide__icon {
  width: 17px;
  height: 17px;
  background-image: url('../images/icons/common/ico-guide-black.svg');
}

.profile__logout__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-logout-black.svg');
}

.pages__white__icon {
  width: 12px;
  height: 12px;
  background-image: url('../images/icons/common/ico-pages-white.svg');
}

.copy__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-copy-black.svg');
}
.copy__btn:hover .copy__icon {
  background-image: url('../images/icons/common/ico-copy-white.svg');
}

.remove__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-remove-black.svg');
}
.remove__btn:hover .remove__icon {
  background-image: url('../images/icons/common/ico-remove-white.svg');
}

.card__remove__icon {
  background-size: 12px 12px;
  background-image: url('../images/icons/common/ico-remove-red.svg');
}
.card__remove__icon:hover {
  background-image: url('../images/icons/common/ico-remove-white.svg');
}

.remove__gray__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-remove-gray.svg');
}

.play__purple__icon {
  width: 11px;
  height: 12px;
  background-image: url('../images/icons/common/ico-play-purple.svg');
}
.play__btn.active .play__purple__icon {
  background-image: url('../images/icons/common/ico-stop-purple.svg');
}

.info__purple__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/common/ico-info-purple.svg');
}
.info__black__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/common/ico-info-black.svg');
}

.fx__icon {
  min-width: 14px !important;
  min-height: 14px !important;
  background-image: url('../images/icons/common/ico-fx.svg');
  cursor: pointer;
}

.sound__black__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/common/ico-sound-black.svg');
}

.timeline__fadeOut__icon {
  width: 18px;
  height: 18px;
  background-image: url('../images/icons/studio-timeline/ico-fade-black.svg');
}

.timeline__fadeIn__icon {
  width: 18px;
  height: 18px;
  background-image: url('../images/icons/studio-timeline/ico-fade-black.svg');
}

.alignment__icon {
  min-width: 12px;
  min-height: 10px;
  background-image: url('../images/icons/common/ico-alignment.svg');
}
.alignment__btn.active .alignment__icon {
  background-image: url('../images/icons/common/ico-alignment2.svg');
}

.etc__gray__icon {
  width: 2px;
  height: 10px;
  background-image: url('../images/icons/common/ico-etc-gray.svg');
}

.arrow__black__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/common/ico-arrow-black.svg');
}

.preview__white__icon {
  width: 30px;
  height: 30px;
  background-image: url('../images/icons/common/ico-preview-btn.svg');
}

.install__black__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-install-black.svg');
}

.copy__black__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-copy-black.svg');
}

.remove__black__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-remove-black.svg');
}

.edit__black__icon {
  width: 16px;
  height: 16px;
  background-image: url('../images/icons/common/ico-edit-black.svg');
}

.prev__black__icon {
  width: 12px;
  height: 12px;
  background-image: url('../images/icons/common/ico-pagination-arrow.svg');
  transform: rotate(180deg);
}
.next__black__icon {
  width: 12px;
  height: 12px;
  background-image: url('../images/icons/common/ico-pagination-arrow.svg');
}

/* main */
.wrap main {
  padding-left: 182px;
}
.studio__wrap main {
  padding-left: 80px;
  padding-right: 80px;
  /* height: 100vh; */
  height: fit-content;
}
/* main end */

/* inner */
header .inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.inner {
  width: calc(100% - 100px);
  height: 100%;
  margin: 0 auto;
}
/* inner end */

/* style */
.flex {
  display: flex;
}
.alignCenter {
  align-items: center;
}
.justifyBetween {
  justify-content: space-between;
}
.relative {
  position: relative;
}
.textAlign__center {
  text-align: center;
}
/* style end */

/* card */
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: relative;
}
.card .img__bg {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.card .card__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}
.card .card__text p {
  font-size: 15px;
  font-weight: 500;
  color: #1a2032;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.card .card__text span {
  font-size: 12px;
  font-weight: 400;
  color: #aaa;
  font-family: 'Montserrat';
}
/* card end */

/* tag */
.tag {
  display: flex;
  align-items: center;
  gap: 6px;
}
.tag > div {
  width: max-content;
  height: 20px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 500;
  padding: 2px 4px;
}
.tag .purple__fill {
  background-color: #7b61ff;
  color: #fff;
}
.tag .purple__strok {
  background-color: #fff;
  color: #7b61ff;
  border: 1px solid #7b61ff;
}
/* tag */

/* card hover content */
.card .hov {
}
.card:hover .hov {
  opacity: 1;
  visibility: visible;
}
.card .hov {
  width: 100%;
  height: 156px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: 50;
  background: rgba(123, 97, 255, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card .etc__icon {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card .etc__box {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}
.card .etc__icon + .content {
  position: absolute;
  bottom: calc(100% + 6px);
  /* left: 50%;
  transform: translateX(-50%); */
  right: 0;
  width: 143px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  padding: 15px;
  padding-bottom: 6px;
}
.card .etc__box .content h4 {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-size: 12px;
  font-weight: 400;
  color: #444;
  margin-bottom: 12px;
}
.card .etc__box .content .col {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card .etc__box .content .col span {
  font-size: 12px;
  font-weight: 400;
  color: #999;
}
.card .etc__box .content .col span:last-child {
  font-family: 'Montserrat';
}
.card .etc__box .content .btn__group {
  margin-top: 5px;
  position: relative;
  padding-top: 5px;
  width: 133px;
  margin-left: -10px;
}
.card .etc__box .content .btn__group::before {
  content: '';
  width: 113px;
  height: 1px;
  background-color: #e9e9e9;
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.card .etc__box .content .btn__group button {
  width: 100%;
  height: 34px;
  border-radius: 5px;
  transition: 0.3s ease;
  font-size: 12px;
  font-weight: 400;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 10px;
  transition: 0.3s ease;
}
.card .etc__box .content .btn__group button span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #666666;
}
.card .etc__box .content .btn__group button:hover {
  background-color: #f8f7ff;
}
.card .etc__box:hover .content {
  opacity: 1;
  visibility: visible;
}
/* card hover content end */

/* header */
main header {
  height: 60px;
  height: 90px;
  background-color: #f8f8f8;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 182px);
  z-index: 100000000;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  transition: 0.3s ease;
}
main header .project__add__btn {
  border-radius: 10px;
  background-color: #2d7bf0;
  background-color: #44505f;
  padding: 11px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  color: #fff;
  transition: 0.3s ease;
}
main header .project__add__btn:hover {
  opacity: 0.7;
}
/* header end */

/* pagination */
.pagination {
  margin-top: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.pagination a {
  width: 34px;
  height: 34px;
  border-radius: 10px;
  transition: 0.3s ease;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Montserrat';
  color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination a:hover {
  background-color: #7b61ff;
  color: #fff;
}
.pagination a.prev__btn:hover {
  background-color: transparent;
}
.pagination a.next__btn:hover {
  background-color: transparent;
}
/* pagination end */

/* select */
.select {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  position: relative;
}
.select.select_st2 {
  height: 36px;
}
.select .select__open.active {
  border: 1px solid #4396f5;
}
.select .select__open {
  border: 1px solid #e9e9e9;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  transition: 0.3s ease;
}
.select.select_st2 .select__open {
  border-radius: 0;
  border: none;
  padding: 0;
}
.select.select_st2.fontFamily__select .select__open {
  min-width: 150px;
}
.select.select_st2.fontWeight__select .select__open {
  min-width: 75px;
}
.select .select__open::after {
  display: none;
}
.select .select__open.active span {
  color: #1a2032;
}
.select .select__open span {
  font-size: 14px;
  font-weight: 400;
  color: #1a2032;
  margin-top: 3px;
}
.select.select_st2 .select__open span {
  font-size: 13px;
}
.select .select__open i {
  width: 16px;
  height: 12.08px;
  transition: 0.3s ease;
}
.select.select_st2 .select__open i {
  width: 10px;
  height: 10px;
}
.select .select__open.active i {
  transform: rotate(180deg);
}
.select .select__open.active + .select__list {
  opacity: 1;
  visibility: visible;
}
.select .select__list {
  position: absolute;
  top: 50px;
  left: 0;
  border-radius: 8px;
  /* border: 1px solid #E9E9E9; */
  width: 100%;
  overflow-y: scroll;
  padding: 20px 6px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  z-index: 100;
  background-color: #fff;
}
.select.select_st2 .select__list {
  top: 40px;
  width: max-content;
  height: 150px;
  padding: 6px 12px;
  padding: 8px 10px;
  overflow-y: scroll;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.select.select_st2 .font-select__list {
  height: unset;
}
.select .select__list li {
  position: relative;
}
.select .select__btn::after {
  display: none;
}
.select.select_st2 .select__btn {
  width: 100%;
  padding: 6px 4px;
  padding: 10px 8px;
  font-size: 12px;
  text-align: left;
  border-radius: 5px;
  transition: 0.3s ease;
}
.select.select_st2 .select__btn.active {
  color: #7b61ff !important;
}
.select.select_st2 .select__btn:hover {
  background-color: #f8f7ff;
}
/* select end */

.side.studio__side {
  width: 80px;
  padding-bottom: 48px;
  z-index: 99999;
}
.side.studio__side .gnb + div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.side.studio__side .membership__upgrade__btn {
  height: 50px;
  height: 42px;
}
.side.studio__side .membership__upgrade__btn:hover {
  background-color: #203039;
}
.side.studio__side {
  background-color: #333a43;
}
.side {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: #f1f4f8;
  width: 182px;
  height: 100vh;
  padding: 44px 0 78px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.side.studio__side .logo img {
  width: 35px;
  width: 40px;
}
.wrap .side .logo img {
  width: 116px;
  width: 134px;
}

/* gnb */
.side.studio__side .gnb {
  width: max-content;
}
.gnb {
  /* margin-top: 90px; */
  position: absolute;
  top: 170px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 64px);
  height: max-content;
}
.gnb li {
  margin-bottom: 25px;
  position: relative;
  left: 4px;
}
.gnb li:last-child {
  margin-bottom: 0;
}
.gnb a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  color: #44505f;
  width: 134px;
  opacity: 0.5;
  padding: 10px 16px 10px 16px;
  margin-left: -10px;
  border-radius: 5px;
  transition: 0.3s ease;
}
.side.studio__side .gnb a {
  opacity: 1;
  width: 44px;
  height: 44px;
  color: #fff;
  justify-content: center;
  border-radius: 5px;
  transition: 0.3s ease;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.side.studio__side .gnb .ballon {
  display: none;
  position: absolute;
  width: 50px;
  height: 36px;
  background: #b1b1b1;
  border-radius: 4px;
  padding: 4px 0px;
  top: -42px;
  right: -3px;
  text-align: center;
}
.ballon:after {
  border-top: 10px solid #b1b1b1;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  top: 34px;
  left: 19px;
}
.gnb li:hover .ballon {
  display: block !important;
}
.membership__upgrade__btn .ballon {
  display: none;
}
.membership__upgrade__btn:hover .ballon {
  display: block !important;
}
.side.studio__side .gnb .ballon span {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: #333a43;
  white-space: nowrap;
}

.gnb a:hover {
  background-color: #e1e8f0;
}

.side.studio__side .gnb a:hover {
  opacity: 1;
  background-color: #293039;
}
.gnb a.current {
  opacity: 1 !important;
}

.side.studio__side .gnb a:hover.ballon {
  display: block;
}

/* gnb end */

/* profile */
.side.studio__side .profile {
  justify-content: center;
}
.profile {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.profile .profile__img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.profile .profile__desc p {
  font-size: 16px;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 400;
  color: #fff;
  color: #44505f;
  margin-bottom: 2px;
  display: block;
}
.profile .cash {
  display: flex;
  align-items: center;
  gap: 5px;
}
.profile .cash .number {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #fff;
  color: #44505f;
}
.profile:hover .profile__desc__hov {
  opacity: 1;
  visibility: visible;
}
.profile__desc__hov {
  position: absolute;
  left: calc(100% + 5px);
  background-color: #fff;
  bottom: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 22px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}
.profile__desc__hov h3 {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-bottom: 6px;
}
.profile__desc__hov p.email {
  font-size: 13px;
  font-weight: 400;
  color: #363d59;
  margin-bottom: 14px;
  font-family: 'Montserrat';
}
.profile__desc__hov .data {
  width: 100%;
  border-radius: 10px;
  background-color: #f7f7f8;
  padding: 14px 12px;
  /* border-bottom: 1px solid #e9e9e9; */
}
.profile__desc__hov .data .flex {
  justify-content: space-between;
}
.profile__desc__hov .data p {
  font-size: 12px;
  font-weight: 400;
  color: #1a2032;
  margin-bottom: 4px;
}
.profile__desc__hov .data .data__num {
  font-size: 12px;
  font-weight: 500;
  color: #9194a4;
  font-family: 'Montserrat';
}
.profile__desc__hov .data .cash {
  display: flex;
  align-items: center;
  margin-left: 5px;
  /* gap: 5px; */
}
.profile__desc__hov .data .cash span {
  font-size: 12px;
  font-weight: 500;
  color: #9194a4;
}
.profile__desc__hov .data a {
  font-size: 12px;
  font-weight: 400;
  color: #7b61ff;
}
.profile__desc__hov .link__group {
  /* border-top: 1px solid #e9e9e9; */
  margin-top: 20px;
  padding-top: 22px;
  width: 185px;
  margin-left: -14px;
  position: relative;
}
.profile__desc__hov .link__group:before {
  content: '';
  display: block;
  width: 161px;
  height: 1px;
  background-color: #e9e9e9;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.profile__desc__hov .link__group a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  border-radius: 5px;
  transition: 0.3s ease;
  gap: 15px;
}
.profile__desc__hov .link__group li:hover {
  background-color: #f8f7ff;
}
.profile__desc__hov .link__group li {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #1a2032;
  transition: 0.3s ease;
  padding: 7.5px 14px;
  border-radius: 8px;
  cursor: pointer;
}
.profile__desc__hov .link__group li:last-child {
  margin-bottom: 0;
}

/* profile end */

/* membership_upgrade_btn */
.membership__upgrade__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 30px;
  margin-bottom: 0px;
  padding: 9px 10px;
  border-radius: 5px;
  transition: 0.3s ease;
}
.membership__upgrade__btn:hover {
  background-color: #e1e8f0;
}
.membership__upgrade__btn p {
  font-size: 13px;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 400;
  color: #fff;
  color: #44505f;
}
/* membership_upgrade_btn end */

/* studio side */
.style__tab {
  position: fixed;
  right: 0;
  top: 0;
  width: 80px;
  height: 100vh;
  z-index: 100000;
  background-color: #e6e6e6;
  padding-top: 80px;
  border-left: 1px solid #d1d1d1;
}
.style__tab > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;
}
.style__tab > ul > li {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.style__tab > ul > li > button {
  /* width: 44px; */
  width: 80px;
  position: relative;
}
.style__tab > ul > li > button > span {
  text-align: center;
  margin-top: 6px;
  font-size: 12px;
  font-weight: 500;
  color: #9194a4;
  letter-spacing: -0.4px;
  transition: 0.3s ease;
}
.style__tab > ul > li > button.active > span {
  color: #7b61ff;
}
.style__tab > ul > li > button > i {
  width: 100%;
  height: 44px;
  /* transition: 0.3s ease-in-out; */
}
.style__tab > ul > li > button::after {
  content: '';
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background-color: #f2f3f5;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 17px;
  transform: scale(0);
  /* transition: 0.42s ease-in-out; */
}
.style__tab button:hover::after {
  transform: scale(1);
}
.style__tab > ul > li > button.active::after {
  background-color: #7b61ff;
  transform: scale(1);
}
/* studio side end */

/* pages list */
.page__list {
  position: relative;
  z-index: 9999;
  width: 216px;
  height: 100%;
  background-color: #e6e6e6;
  /* margin-left: 80px; */
  padding: 30px 18px;
  overflow: hidden;
  overflow-y: auto;
  border-right: 1px solid #d1d1d1;
}
.page__list .title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-bottom: 30px;
}
.page__list .title h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  color: #000;
}
.page__list .title .total {
  padding: 3px 9px;
  border-radius: 12px;
  border: 1px solid #7b61ff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #7b61ff;
  margin-top: 2px;
}
.page__list .title .total::after {
  content: attr(data-total-number);
  font-size: 10px;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #7b61ff;
}
.page__list .list li:first-child {
  margin-top: 0;
}
.page__list .list li:last-child {
  margin-top: 0;
}
.page__list .list li {
  width: 100%;
  height: 102px;
  border-radius: 5px;
  margin-top: 56px;
  position: relative;
}

.page__list .list .transitionIcon {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 3px;
  position: relative;
  left: 50%;
  /* bottom: -35px; */
  top: 27px;
  transform: translateX(-50%);
  background: url('../images/icons/common/ico-transitionNot.svg') no-repeat
    center / cover;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.05));
  cursor: pointer;
}
.page__list .list .transitionIcon.active {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-image: url('../images/icons/common/ico-transition-purple.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.page__list .list li.state__transition::after {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  bottom: -35px;
  transform: translateX(-50%);
  background: url('../images/icons/common/ico-transition.svg') no-repeat center /
    cover;
  cursor: pointer;
}
.page__list .list li:nth-last-child(2)::after {
  display: none;
}
.page__list .list li .card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #222;
}
.page__list .list li .card.card_add__box {
  background-color: initial;
}
.page__list .list li .card.card::before {
}
.page__list .list li .card.active {
  outline: 3px solid #7157f5;
  border-radius: 5px;
}
.page__list .list .add__box {
  display: flex;
  gap: 8px;
  /* justify-content: center; */
  align-items: center;
  width: 100px;
  width: 32px;
  height: 32px;
  padding: 0 0 0 32px;
  background-color: #7b61ff;
  border-radius: 22px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s ease;
  position: relative;
  cursor: default;
}
.page__list .list .add__box span {
  opacity: 0;
  visibility: hidden;
  /* transition: 0.1s ease; */
}
.page__list .list .add__box:hover {
  width: 100px;
}
.page__list .list .add__box:hover span {
  transition: 0.1s 0.1s ease;
  opacity: 1;
  visibility: visible;
}
.page__list .list .add__box i.plus__icon {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: url('../images/icons/common/ico-plus-raduis-white.svg') no-repeat
    center / cover;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.page__list .list .add__box:hover::before {
  content: '';
  width: 100%;
  height: 10px;
  position: absolute;
  top: 100%;
  left: 0;
}
.page__list .list .add__box:hover .addPage__btn__list {
  opacity: 1;
  visibility: visible;
}
.page__list .addPage__btn__list {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: calc(100% + 4px);
  transform: translateX(-50%);
  width: 100px;
  padding: 4px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: 0.3s ease;
}
.page__list .addPage__btn__list button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #363d59;
  height: 34px;
  border-radius: 5px;
  transition: 0.3s ease;
}
.page__list .addPage__btn__list button:hover {
  background-color: #f8f7ff;
}

.page__list .card {
  border-radius: 10px;
  position: relative;
  transition: 0.3s ease;
}
.page__list .card .bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.page__list .card .card__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 8px;
  z-index: 10;
  width: 100%;
  padding: 0 10px;
}
.page__list .card .card__content .page__num {
  display: flex;
  align-items: center;
  gap: 4.5px;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat';
  color: #fff;
}
.page__list .card .card__content .timer {
  font-size: 11px;
  font-weight: 500;
  font-family: 'Montserrat';
  color: #fff;
}
.page__list .card .bg::before {
  content: '';
  width: 100%;
  height: 54px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.025) 34.07%,
    rgba(0, 0, 0, 0.15) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  border-radius: 10px;
}
.page__list .card:hover .hov {
  opacity: 1;
  visibility: visible;
}
.page__list .card .hov {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  background: rgba(123, 97, 255, 0.3);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}
.page__list .card .hov .other {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background-color: #fff;
}
.page__list .card .hov .other > span {
  width: 2px;
  height: 2px;
  border-radius: 100%;
  background-color: #adadad;
}
.page__list .card .hov .other__btn__list {
  position: absolute;
  left: -105px;
  bottom: calc(100% + 8px);
  width: 124px;
  height: max-content;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 4px;
  opacity: 0;
  visibility: hidden;
  z-index: 10000;
  transition: 0.3s ease;
}
.page__list .card .hov .other:hover .other__btn__list {
  opacity: 1;
  visibility: visible;
}
.page__list .card .hov .other__btn__list button {
  width: 100%;
  height: 34px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #666;
  padding: 0 11px;
  transition: 0.3s ease;
}
.page__list .card .hov .other__btn__list button:hover {
  color: #fff;
  background-color: #7b61ff;
}
.page__list .card .hov .other__btn__list button i {
  transition: 0.3s ease;
}
.page__list .list {
  position: absolute;
  width: 80%;
}
/* pages list end */

/* studio layout */
.studio__main {
  width: 100%;
  width: calc(100vw - 80px - 216px);
  /* height: 100%; */
  height: fit-content;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  grid-template-rows: auto 50px 401.46px;
  grid-template-rows: auto 50px 363px;
  padding-top: 80px;
  /* overflow-y: auto; */
}
/* width */
.studio__main::-webkit-scrollbar {
  width: 6px;
}
/* Track */
.studio__main::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.studio__main::-webkit-scrollbar-thumb {
  background: #e9e9e9;
  border-radius: 10px;
}
/* Handle on hover */
.studio__main::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}
/* studio layout end */

/* studio header */
.studio__header {
  background-color: #fff;
  height: 80px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  width: calc(100vw - 80px - 214px - 80px);
  position: absolute;
  left: 297px;
  z-index: 1000;
}
.studio__header .title {
  display: flex;
  align-items: center;
  /* margin-left: 8px; */
}
.studio__header .title h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
}
.studio__header .title button {
  margin-left: 6px;
}
.studio__header .title input {
  min-width: max-content;
  max-width: max-content;
  width: max-content;
  height: 32px;
  border: none;
  outline: none;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 0 11px;
  /* display: none; */
  min-width: 80px;
  max-width: 500px;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
}
.studio__header .title.active h2 {
  display: none;
}
.studio__header .title.active input {
  display: block;
}

.studio__header .save__btn {
  margin-right: 10px;
  width: 104px;
  height: 38px;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  border-radius: 5px;
}
.studio__header .save__btn:hover {
  /* background-color: #f2f3f5; */
  background-color: #f9f9f9;
}
.studio__header .save__btn p {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #363d59;
  margin-right: 10px;
}
.studio__header .save__btn i {
  width: 24px;
  height: 24px;
}

.studio__header .preview__btn {
  height: 38px;
  background-color: #efefef;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-right: 10px;
  transition: 0.3s ease;
}
.studio__header .preview__btn:hover {
  background-color: #f9f9f9;
}
.studio__header .preview__btn p {
  font-size: 13px;
  font-weight: 500;
  color: #363d59;
}
.studio__header .preview__btn span {
  width: 20px;
  width: max-content;
  text-align: right;
  border-left: 1px solid #d1d1d1;
  margin-left: 10px;
  padding-left: 8px;
  font-size: 13px;
  font-weight: 500;
  color: #333a43;
}
.studio__header .export__btn {
  width: 104px;
  height: 38px;
  border-radius: 5px;
  background-color: #333a43;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
}

.studio__header .export__btn p {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-right: 10px;
}

.studio__header .export__btn i {
  width: 24px;
  height: 24px;
}

.studio__header .export__btn:hover {
  background-color: #7157f5;
}
/* studio header end */

/* studio popup */
.preview-popup {
  width: 442px;
  height: auto;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 23px 26px 26px 26px;
  position: absolute;
  top: 400%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  display: flex;
  flex-direction: column;
  padding: 26px;
  border: 1px solid #d1d1d1;
}
.popupClose {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #f2f3f5;
  position: absolute;
  right: 14px;
  top: 14px;
}
.preview-icon {
  width: 34px;
  height: 34px;
  margin: 0 auto;
}
.preview-popup h3 {
  font-weight: 500;
  font-size: 16px;
  color: #1a2032;
  margin-top: 19px;
  text-align: center;
}
.preview-popup p {
  font-weight: 400;
  font-size: 14px;
  color: #9194a4;
  /* margin-top: 6px; */
  text-align: center;
}
.popup-select-cont {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1.5px solid #e9e9e9;
  border-radius: 8px;
  margin-top: 18px;
  position: relative;
}
.popup-select-cont button {
  border: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #1a2032;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.popup-btn-cont {
  margin-top: 38px;
  width: 100%;
  height: 50px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
}
.popupBtnLeft {
  width: 130px;
  height: 100%;
  background: #f2f3f5;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #1a2032;
  opacity: 0.54;
}
.popupBtnRight {
  background: #7b61ff;
  border-radius: 5px;
  width: 242px;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.54;
}
.popup-select-box {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: visible;
  background: #ffffff;
  border: 1px solid #dfe0e5;
  border-radius: 8px;
  position: absolute;
  margin-top: 54px;
  z-index: 9;
  padding: 9px 10px;
  opacity: 1;
  /* visibility: hidden; */
  transition: 0.3s ease;
}
.popup-select-box ul li {
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s ease;
}
.popup-select-box ul li:hover {
  background: #f8f7ff;
  border-radius: 5px;
}
.popup-select-box ul li span {
  font-weight: 500;
  font-size: 14px;
  color: #1a2032;
  font-family: 'Montserrat';
}
.popup-select-box ul li > div {
  display: flex;
  gap: 10px;
}
.pageTime {
  font-weight: 400;
  font-size: 13px;
  color: #9a88f7;
  font-family: 'Montserrat';
}
.page-section {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #9a88f7 !important;
  font-family: 'Montserrat' !important;
}
.preview-popup strong {
  font-weight: 700;
  font-size: 16px;
  color: #222222;
}
.preview-img-cont {
  width: 536px;
  height: auto;
  border-radius: 10px;
  margin-top: 24px;
  overflow: auto;
}
.popup-btn-cont-vedio {
  width: auto;
  height: 50px;
  margin-top: 29px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.popupBtnClose {
  width: 100px;
  height: 100%;
  background: #f2f3f5;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #363d59;
}
.popupBtnSend {
  width: 130px;
  height: 100%;
  background: #7b61ff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.opacity-1 {
  opacity: 1;
}
.width-558 {
  width: 588px;
}
.top-12 {
  margin-top: 30px;
}
.selectHD {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #bdbdbd;
  font-family: 'Montserrat';
}
.project-move-btn {
  width: 390px;
  height: 50px;
  background: #7b61ff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin-top: 30px;
}
.music-icon {
  width: 138px;
  height: 138px;
  margin: 0 auto;
}
.exclamationMark-icon {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}
.popup-select-cont button.active .popup-select-box {
  opacity: 1;
  visibility: visible;
}
.popup-select-cont button.active .popup-arrow-icon {
  transform: rotate(180deg);
}
.popup-arrow-icon {
  transition: 0.3s ease;
}

/* studio popup */

/* studio canvas */
.studio__canvas {
  height: 576px;
  height: 100%;
  min-height: 576px;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  width: calc(100vw - 80px - 216px - 80px);
}
.studio__canvas > div {
  /* background-color: #1A2032; */
  z-index: 10;
  /* margin: auto; */
  position: relative;
  width: max-content;
  height: 100%;
  margin: auto;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 6%);
  /* border: 1px solid #efefef; */
}
.studio__canvas > div .content {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.studio__canvas > div .canvas__size {
  /* width: 100%; */
  /* height: max-content; */
  /* height: 100%; */
  margin: 0 auto;
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%); */
}
.studio__canvas > div .canvas__size > img {
  width: auto;
  height: 100%;
}
.studio__canvas .preview__background_image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
/* studio canvas end */

/* clipMenu */
.clipMenu-cont {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
}
.clipMenu {
  width: 102px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.3);
}
.clipMenu ul {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 100%;
  flex-direction: row;
  position: relative;
}
.clipMenu ul li {
  width: 100%;
  height: 100%;
}
.clipMenu ul li button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.clipMenu.active {
  opacity: 1;
  visibility: visible;
}
.select-arrow.active .clip-down-cont {
  opacity: 1;
  visibility: visible;
}
.arrow-down {
  transition: 0.3s ease;
}
.select-arrow.active .arrow-down {
  transform: rotate(180deg);
}
.select-arrow button {
  display: flex;
  gap: 3px;
  align-items: center;
}
.clip-down-cont {
  width: 32px;
  height: 104px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  /* opacity: 0;
  visibility: hidden; */
  transition: 0.3s ease;
  padding: 10px;
}
.clip-down-cont ul {
  display: flex;
  gap: 12px;
  flex-direction: column;
}
/* studio canvas end */

/* studio timeline */
.studio__tool {
  width: calc(100vw - 370px);
  height: 50px;
  padding: 0 80px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d1d1d1;
  /* position: fixed; */
  bottom: calc(40vh - 135px);
}
.studio__tool .col {
  gap: 8px;
}
.studio__tool .col > button {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  transition: 0.3s ease;
}
.studio__tool .col .ballon__tool > button {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  transition: 0.3s ease;
}

.clip-down-btn .ballon__tool {
  display: none;
  position: absolute;
  width: 64px;
  height: 32px;
  /* bottom: 42px; */
  left: 38px;
  text-align: center;
  /* padding: 6px 12px; */
  background: #333a43;
  border-radius: 4px;
  z-index: 99;
}

.clip-down-btn .clipMenu__tool {
  display: none;
  position: absolute;
  width: 64px;
  height: 32px;
  /* bottom: 42px; */
  top: 37px;
  left: -14px;
  text-align: center;
  /* padding: 6px 12px; */
  background: #333a43;
  border-radius: 4px;
  z-index: 99;
}

.clip-down-btn .ballon__tool span {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.clip-down-btn .clipMenu__tool span {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.clip-down-btn:hover > .ballon__tool {
  border-radius: 4px;
  width: 46px;
  height: 26px;
  transition: 0.3s ease;
  display: block !important;
}

.clip-down-btn:hover > .clipMenu__tool {
  border-radius: 4px;
  width: 46px;
  height: 26px;
  transition: 0.3s ease;
  display: block !important;
}

.clip-down-btn .ballon__tool:after {
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-right: 10px solid #333a43;
  border-bottom: 6px solid transparent;
  content: '';
  position: absolute;
  top: 7px;
  left: -16px;
}

.clip-down-btn .clipMenu__tool:after {
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #333a43;
  content: '';
  position: absolute;
  top: -15px;
  left: 16px;
}

.studio__tool .col .ballon__tool {
  display: none;
  position: relative;
  width: 64px;
  height: 32px;
  bottom: 42px;
  left: -16px;
  text-align: center;
  /* padding: 6px 12px; */
  background: #333a43;
  border-radius: 4px;
  z-index: 99;
}
.studio__tool .col .ballon__tool span {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
}
.ballon__tool:after {
  border-top: 10px solid #333a43;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  top: 32px;
  left: 26px;
}
.studio__tool .col .ballon__tool > button:hover {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  transition: 0.3s ease;
}
.studio__tool .col > button:hover .ballon__tool {
  display: block !important;
}
.studio__tool .col > button:hover {
  background-color: #f2f3f5;
}
.studio__tool .scale__box {
  display: flex;
  align-items: center;
  gap: 6px;
  /* margin-right: 16px; */
}
.studio__tool .scale__box button {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  transition: 0.3s ease;
}
.studio__tool .scale__box button:hover {
  background-color: #f2f3f5;
}
.studio__tool .scale__box .range {
  width: 90px;
  height: 2px;
}
.studio__tool .scale__box input[type='range'] {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  height: 10px;
  width: 90px;
  cursor: pointer;
  border-radius: 0; /* iOS */
}
.studio__tool .scale__box input[type='range'] {
  background: linear-gradient(to right, #b635f5 0%, #6dd1f0 50%, #e9e9e9 80%);
  border-radius: 8px;
  height: 2px;
  width: 90px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.studio__tool .scale__box input[type='range']::-webkit-slider-thumb {
  background-color: #fff;
  border: solid 3px #7b61ff;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  -webkit-appearance: none;
}

.studio__tool .tool__time {
  background: #e8e9eb;
  border-radius: 29px;
  padding: 4px 8px;
}
.studio__tool .tool__time span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #1a2032;
}

.studio__timeline {
}
.studio__timeline .time__box {
  width: 100%;
  height: 40px;
  position: relative;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  background: #efefef;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  /* overflow-y: hidden; */
  /* top: 0; */
  /* position: sticky; */
  display: inline-flex;
  /* z-index: 100000000; */
}
.studio__timeline.test {
  overflow-x: auto;
  overflow-y: auto;
}
.studio__timeline .time__box .time {
  width: 136px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 28px; */
  background-color: #efefef;
  /* margin: 5px 0; */
  position: sticky;
  /* border: 1px solid; */
  border-right: 1px solid #d1d1d1;
  z-index: 100000000000;
  top: 0;
  left: 0;
}

/* .time {
  width: 102px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  background-color: #f2f3f5;
  margin: 5px 25px;
} */
.time {
  width: 136px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 28px; */
  background-color: #efefef;
  /* margin: 5px 0; */
  position: sticky;
  /* border: 1px solid; */
  border-right: 1px solid #d1d1d1;
  z-index: 1001;
  top: 0;
  left: 0;
}

.time span {
  color: #1a2032;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.03em;
  line-height: 18px;
  text-align: center;
}
.w-full.test {
  /* overflow-x: auto; */
  /* overflow-y: hidden; */
  flex-grow: 0;
  flex-shrink: 0;
}

.studio__timeline .content__box {
  /* border-top: 1px solid #e9e9e9; */
  /* display: flex; */
  /* overflow-y: auto; */
  display: inline-flex;
  height: calc(100% - 38px);
}

.title__line {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 44px;
  justify-content: left;
  width: 135px;
  padding-left: 22px;
  left: 0;
  z-index: 1002;
  background-color: white;
}
.title__line .button {
  position: absolute;
  right: 10px;
}
.title__line .button.close {
  content: '';
  position: absolute;
  right: 10px;
  width: 6.42px;
  height: 6.42px;
  background: url('../images/icons/studio-timeline/close.svg') no-repeat center /
    cover;
  cursor: pointer;
}
.timeline_icon {
  width: 18px;
}
.title__line span {
  color: #1a2032;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.03em;
  line-height: 16.8px;
}
.strip_box {
  height: 44px;
}
.strip_box .title .title__line {
  border-bottom: 1px solid #e9e9e9;
}
.strip_box .blockContainer {
  background: repeating-linear-gradient(
    #fff,
    #fff 42px,
    #e9e9e9 43px,
    #e9e9e9 44px
  );
}
.studio__timeline .content__box .title__line {
  padding: 12px 16px;
  padding: 0 16px;
  width: 136px;
  height: 100%;
  border-right: 1px solid #d1d1d1;
  position: sticky;
  background-color: white;
  /* overflow-x: hidden; */
  flex-grow: 0;
  flex-shrink: 0;
  top: 40px;
  left: 0;
  z-index: 1000000000;
}

.studio__timeline .content__box .title__line li {
  width: 100px;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 42px;
  /* margin-bottom: 24px; */
  justify-content: left;
  margin-left: 10px;
}
.studio__timeline .content__box .title__line li:last-child {
  margin-bottom: 0;
}
.studio__timeline .content__box .title__line li span {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: -0.03em;
  color: #1a2032;
}
.title__line .subrail {
  font-size: 12px;
}

.studio__timeline .content__box .content__line {
  width: calc(100% - 136px);
  position: relative;
  z-index: -10;
  /* overflow-x: auto; */
  /* width: calc(100vw - 80px - 216px - 80px - 136px); */
}

.studio__timeline .content__box .content__line .rails {
  min-height: 42px;
  border-bottom: 0.5px dashed #d1d1d1;
}

.studio__timeline .content__box .content__line .marker:hover {
  cursor: ew-resize;
}

.studio__timeline .content__box .content__line .marker {
  position: absolute;
  top: -3px;
  outline: none;
  line-height: 30px;
  bottom: -336px;
  left: 20px;
  border: 1px solid #7b61ff;
  z-index: 1000000;
}
.marker::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -14px;
  width: 12px;
  height: 16px;
  background: url('../images/icons/studio-timeline/marker-purple.svg') no-repeat
    center / cover;
  cursor: pointer;
}
.marker__time {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 20px;
  border-radius: 20px;
  background-color: #7b61ff;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.show__marker__time {
  position: absolute;
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 20px;
  border-radius: 20px;
  background-color: #7b61ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 1;
  visibility: visible;
  z-index: 10;
}

.studio__timeline .time__box .time__progress {
  position: absolute;
  top: -2px;
  left: 134px;
  width: calc(100% - 136px);
  /* height: 100%; */
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding-right: 27px;
  position: sticky;
  top: 0;
}
.studio__timeline .time__box .time__progress span {
  width: 2px;
  height: 10px;
  background-color: #d1d1d1;
  display: block;
  position: relative;
}
.studio__timeline .time__box .time__progress span em {
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 600;
  color: #363d59;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  font-style: normal;
}

.time__progress span em {
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 600;
  color: #363d59;
  position: absolute;
  left: 50%;
  bottom: -15px;
  transform: translateX(-50%);
  font-style: normal;
}

.timeline_icon {
  width: 18px;
}

/* studio timeline end */

/* style__tab */
.style__tab > ul > li > button + div {
  position: fixed;
  top: 0;
  top: 40px;
  top: 64px;
  /* right: calc(100% + 20px); */
  right: 100px;
  width: 316px;
  height: 605px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding: 16px 25px 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  /* overflow-y: scroll;
  overflow-x: hidden; */
}
.style__tab > ul > li > button + div.text {
  height: 510px;
}
.style__tab > ul > li > button.active + div {
  opacity: 1;
  visibility: visible;
  height: 66%;
}
.style__tab li button + div .close__btn,
.popupBtn {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #f2f3f5;
}
.style__tab li button + div .close__btn {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #f2f3f5;
}
.style__tab li button + div .close__btn::after {
  display: none;
}
.style__tab li button + div .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}
.style__tab li button + div .title h2 {
  font-size: 15px;
  font-weight: 600;
  color: #1a2032;
}
/* style__tab end */

/* style__tab content */
.style__tab .metahuman .metahuman-cont {
  margin-bottom: 26px;
}
.style__tab .metahuman .metahuman-cont h3 {
  font-size: 14px;
  font-weight: 400;
  color: #1a2032;
  margin-bottom: 14px;
}
.meta-edit-cont {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.border-20 {
  padding-bottom: 20px;
  border-bottom: 1px solid #f2f3f5;
}
.edit-box h3 {
  font-size: 12px;
  font-weight: 500;
  color: #a3aab8;
}
.edit-input-box {
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
}
.edit-input-cont {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 10px;
}
.edit-input-cont span {
  font-weight: 400;
  font-size: 12px;
  color: #acafc0;
}
.edit-input-cont input {
  width: 70px;
  height: 32px;
  background: #fafbfd;
  box-shadow: inset 0px -1px 10px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: 0;
  padding: 8px 13px;
  text-align: center;
}
.edit-btn {
  width: 10px;
  height: 16px;
  position: relative;
}
.change-cont {
  position: relative;
  margin-top: 10px;
}
.edit-change {
  width: 100%;
  height: 50px;
  border: 1px solid #7b61ff;
  border-radius: 8px;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #7b61ff;
  position: relative;
}
.edit-change img {
  right: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.edit-new {
  height: 50px;
  background: #7b61ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: sticky;
  bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding: 0;
  width: 266px;
}
.width-tmpbox {
  position: absolute;
  width: max-content;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 600;
  left: -600px;
  visibility: hidden;
}

.img__list.list.show.list.default {
  position: relative;
  height: 10%;
}

.change-popup {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  width: 178px;
  height: 87px;
  padding: 18px 26px;
  position: absolute;
  top: 36px;
  right: -65px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #9194a4;
  display: none;
}

.change-popup .edit-new {
  border-radius: 10px;
  text-align: center;
  position: fixed;
  bottom: 210px;
}

.change-popup.active {
  display: block;
}

.meta-add-btn {
  width: 266px;
  height: 50px;
  background: #7b61ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  bottom: 26px;
  z-index: 999;
}

.nextBtn {
  width: 123px;
  height: 50px;
  background: #7b61ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  right: 22px;
  bottom: 30px;
}
.style__tab .metahuman .content .col {
  margin-bottom: 10px;
}
.style__tab .metahuman .select__btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  height: 70px;
  border-radius: 8px;
  transition: 0.3s ease;
}
.style__tab .metahuman .select__btn.active {
  background-color: #f3f8fc;
}
.style__tab .metahuman .select__btn .img__wrap {
  width: 70px;
  height: 48px;
  border-radius: 5px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.style__tab .metahuman .select__btn h3 {
  width: 96px;
  padding: 0 6px 0 18px;
  font-size: 14px;
  font-family: 'Noto Sans Kr';
  font-weight: 500;
  color: #1a2032;
  text-align: left;
}
.style__tab .metahuman .tag__list {
  display: flex;
  align-items: center;
  gap: 6px;
}
.style__tab .metahuman .tag__list > span {
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 500;
  margin: 0;
}
.style__tab .metahuman .blue__fill__tag {
  background-color: #4396f5;
  color: #fff;
}
.style__tab .metahuman .blue__strok__tag {
  background-color: #fff;
  color: #4396f5;
  border: 1px solid #4396f5;
}

.style__tab .metahuman__select .select__list {
  height: 425px;
}
.style__tab .style__select .select__list {
  height: 366px;
}
.style__tab .style__select .voice__list {
  height: 220px;
}

.style__tab .metahuman .voice__select .select__btn {
  height: 50px;
  position: relative;
}
.style__tab .metahuman .voice__select .select__btn h3 {
  padding-left: 0;
}
.style__tab .metahuman .voice__select .play__box {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 14px;
  width: max-content;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
}
.style__tab
  .metahuman
  .voice__select
  .select__open.active
  + .select__list
  .play__box {
  opacity: 1;
  visibility: visible;
}

.style__tab .metahuman .voice__select .play__box span {
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #9194a4;
}
.style__tab .metahuman .voice__select .play__box button {
  width: 24px;
}
.style__tab .metahuman .voice__select .play__box button::after {
  display: none;
}

.style__tab .metahuman .content .col.st2 {
  margin-top: 56px;
  width: 100%;
}
.style__tab li button + div .col .title {
  margin-bottom: 10px;
}
.style__tab .metahuman .title h3 {
  font-size: 14px;
  font-weight: 400;
  color: #1a2032;
}
.style__tab .metahuman .script__list {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  padding: 0 16px;
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.style__tab .metahuman .script__list button {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 16px 0;
}
.style__tab .metahuman .script__list button p {
  font-size: 13px;
  font-weight: 400;
  color: #1a2032;
  padding-top: 1px;
}

.style__tab .metahuman .script__list .script__item:last-child button {
  border-top: 1px solid #e9e9e9;
}
.style__tab .metahuman .script__list button i {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.style__tab .metahuman .script__list .checkbox {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 1px solid #9194a4;
  position: relative;
  margin: 0;
  transition: 0.3s ease;
}
.style__tab .metahuman .script__list .checkbox::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease;
}
.style__tab .metahuman .script__list .script__item.active .checkbox {
  border: 1px solid #80b8fa;
}
.style__tab .metahuman .script__list .script__item.active .checkbox::after {
  background-color: #4396f5;
}
.style__tab .metahuman .script__list .script__item i {
  opacity: 0.15;
}
.style__tab .metahuman .script__list .script__item.active i {
  opacity: 1;
}
.style__tab
  .metahuman
  .script__list
  .script__item:first-child.active
  .script__text__textarea {
  display: block;
}
.style__tab
  .metahuman
  .script__list
  .script__item:last-child.active
  .script__motionPreset__box {
  display: flex;
}
.style__tab .metahuman .script__list .script__text__textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 0px;
  max-height: 0px;
  height: 0px;
  width: 100%;
  border-radius: 8px;
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  min-height: 114px;
  max-height: 114px;
  height: 114px;
  padding: 4px;
  border: 1px solid #4396f5;
  color: #1a2032;
  display: none;
  /* transition: min-height 0.3s ease, max-height 0.3s ease, height 0.3s ease, padding 0.3s ease; */
}
.style__tab .metahuman .script__list .script__motionPreset__box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: max-content;
  right: 0;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  opacity: 1;
  visibility: visible;
  padding: 0;
  border: none;
  display: none;
}
.style__tab .metahuman .script__list .script__motionPreset__box button::after {
  display: none;
}
.style__tab .metahuman .script__list .script__motionPreset__box button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: #1a2032;
  padding: 12px 0;
  border: none !important;
}

.style__tab .save__btn::after {
  display: none;
}
.style__tab .save__btn {
  width: 130px;
  height: 52px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4396f5;
  transition: 0.3s ease;
  position: absolute;
  right: 22px;
  bottom: 22px;
}
.style__tab .save__btn:hover {
  opacity: 0.8;
}
.style__tab .save__btn span {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  padding: 0;
}

.style__tab .tab__list {
  display: flex;
  width: 316px;
  margin-left: -22px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 16px;
  margin-bottom: 20px;
}
.style__tab .tab__list button {
  font-size: 14px;
  font-weight: 400;
  color: #acafc0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.3s ease;
}
.style__tab .tab__list button:not(:last-child)::after {
  content: '';
  width: 1px;
  height: 10px;
  background-color: #e9e9e9;
  display: block;
  position: absolute;
  right: 0;
  left: auto;
  top: 6px;
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  border-radius: 0;
  z-index: 1;
}
.style__tab .tab__list button.active {
  color: #7b61ff;
  font-weight: 700;
}
.style__tab .content > div {
  display: none;
}
.style__tab .content > div.show {
  display: block;
}
.style__tab .button__list {
  display: flex;
  width: 100%;
  border-radius: 8px;
  background-color: #efefef;
  padding: 4px;
  margin-bottom: 20px;
}
.style__tab .button__list button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  border-radius: 8px;
  color: #9194a4;
  transition: 0.3s ease;
}
.style__tab .button__list button.active {
  color: #1a2032;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.style__tab .content > div .list {
  display: none;
}
.style__tab .content > div .list.show {
  display: block;
}

.style__tab .content > div .list ul {
  padding-right: 12px;
  margin-right: -12px;
}
.style__tab .content > div .list.my {
  /* padding-right: 12px; */
  margin-right: -12px;
}
.style__tab .content > div .list.default ul {
  /* height: 380px; */
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.style__tab .content > div .list.default li:last-child {
  /* margin-bottom: 80px; */
}

.style__tab .content > div .list.default.transition__list ul {
  /* min-height: 500px; */
  height: auto;
  display: flex;
  flex-direction: column;
}
.transition__list {
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  min-height: 500px;
}
/* width */
.style__tab .content > div .list ul::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.style__tab .content > div .list ul::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.style__tab .content > div .list ul::-webkit-scrollbar-thumb {
  background: #e9e9e9;
  border-radius: 10px;
}
/* Handle on hover */
.style__tab .content > div .list ul::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}

/* width */
.style__tab .content > div .list.my::-webkit-scrollbar {
  width: 5px;
}
.style__tab .content > div .list.my::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.style__tab .content > div .list.my::-webkit-scrollbar-thumb {
  background: #e9e9e9;
  border-radius: 10px;
}
/* Handle on hover */
.style__tab .content > div .list.my::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}
.style__tab .content > div .list.my {
  /* overflow-y: scroll; */
  /* margin-bottom: 80px; */
}
.style__tab .content > div .list.my ul {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  /* height: 220px; */
}
.style__tab .content > div .list ul button .img__bg::after {
  /* content: ''; */
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  border-radius: 10px;
  transition: 0.3s ease;
}
.style__tab .content > div .list ul button:hover .img__bg::after {
  background-color: rgba(123, 97, 255, 0.3);
  opacity: 1;
  visibility: visible;
}
.style__tab .content > div .list.my ul li {
  position: relative;
}
.style__tab .content > div .list.my ul li:hover .remove__btn {
  opacity: 1;
  visibility: visible;
}
.style__tab .content > div .list.my ul .remove__btn {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}
.style__tab .content > div .list.my ul .remove__btn:hover {
  background-color: #ff6060;
}
.style__tab
  .content
  > div
  .list.my
  ul
  .remove__btn:hover
  + button
  .img__bg::after {
  background-color: rgba(123, 97, 255, 0.3);
  opacity: 1;
  visibility: visible;
}

.style__tab .content > div .list.my ul li:hover .btn__hov {
  opacity: 1;
  visibility: visible;
}
.style__tab .content > div .list li {
  width: calc(50% - 6px);
  /* height: 30%; */
}
.style__tab .content > div .list.list_st2 {
  gap: 10px;
  height: 380px;
}
.style__tab .content > div .list.list_st2 ul {
  flex-direction: column;
  flex-wrap: nowrap;
}
.style__tab .content > div .list.list_st2 li {
  width: 100%;
  min-height: 50px;
  position: relative;
  height: 50px;
}
.style__tab .content > div .list.list_st2 li button.play__btn {
  width: max-content;
  position: absolute;
  left: auto;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #9194a4;
  opacity: 1;
  visibility: visible;
  z-index: 100;
}
.style__tab .content > div .list.list_st2 li button.play__btn span {
  font-family: 'Montserrat';
  font-weight: 400;
  transition: 0.3s ease;
}
.style__tab .content > div .list.list_st2 li button.play__btn i {
  transition: 0.3s ease;
}
.style__tab .content > div .list.list_st2 li button:not(.play__btn) {
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  height: 100%;
  font-size: 13px;
  font-weight: 400;
  color: #1a2032;
  padding-top: 2px;
  transition: 0.3s ease;
}
.style__tab .content > div .list.list_st2 li button:not(.play__btn) span {
  max-width: 160px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.style__tab .content > div .list.list_st2 li:hover button:not(.play__btn) {
  border: 1px solid rgba(123, 97, 255, 0.05);
  background-color: rgba(123, 97, 255, 0.05);
}

.style__tab .content > div .list.list_st2 li .play__btn:hover + button {
}
.style__tab .content > div .list.list_st2 li button:not(.play__btn):hover {
  border: 1px solid rgba(123, 97, 255, 0.05);
  background-color: rgba(123, 97, 255, 0.05);
}
.style__tab .content > div .list.list_st2 li button:not(.play__btn).active {
  border: 1px solid #7b61ff;
}

.style__tab .content > div .list button {
  width: 100%;
  height: 50px;
  position: relative;
}

.style__tab .content > div .list button.active > .text_wrap {
  border: 1px solid #7b61ff;
  background: #f8f7ff;
}

.style__tab .content > div .list.list_st2 li button.remove__btn {
  position: absolute;
  left: auto;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  padding: 0;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  border: none !important;
}
.style__tab .content > div .list.list_st2 li:hover button.remove__btn {
  opacity: 1;
  visibility: visible;
}
.style__tab .content > div .list.list_st2 li button.remove__btn.active {
  border: none;
}
.style__tab .content > div .list.list_st2.my li:hover button.play__btn span {
  opacity: 0;
  visibility: hidden;
}
.style__tab .content > div button .img__bg {
  width: 100%;
  height: 90px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: relative;
  background-color: #7b61ff1d;
}

.style__tab .content > div .list li:hover .img__bg_hov {
  opacity: 0.3;
  visibility: visible;
}

.img__bg_hov {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  background: #7b61ff;
  width: 100%;
  height: 90px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.style__tab .content > div button .img__bg::before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 2.4px solid #7b61ff;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.style__tab .content > div button.active .img__bg::before {
  opacity: 1;
  visibility: visible;
}

.style__tab .content > div button p {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 5px;
}
.style__tab .file__upload__box {
  width: 100%;
  height: 130px;
  border: 1px dashed #e9e9e9;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}
.style__tab .file__upload__box .file__upload {
  display: none;
}
.style__tab .file__upload__box .file__upload + label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.style__tab .file__upload__box .file__upload + label img {
  width: 36px;
  margin-bottom: 8px;
}
.style__tab .file__upload__box .file__upload + label h3 {
  font-size: 13px;
  font-weight: 500;
  color: #666;
  margin-bottom: 9px;
}
.style__tab .file__upload__box .file__upload + label p {
  font-size: 12px;
  font-weight: 400;
  color: #adadad;
  line-height: 1.5;
  text-align: center;
}
.style__tab .file__upload__box .file__upload + label p span {
  color: #7b61ff;
}
.style__tab .file__upload__box .upload_loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
.style__tab .file__upload__box .upload_loading .upload-loding-text {
  position: absolute;
  color: #666;
  top: 80px;
  font-size: 13px;
}
.style__tab .video__time {
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  position: absolute;
  left: 6px;
  bottom: 30px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}
.style__tab .content > div .list li:hover .video__time {
  opacity: 1;
  visibility: visible;
}
.style__tab .content > div.transition__content ul li p {
  text-align: left;
  font-size: 13px;
  color: #1a2032;
}
.style__tab > ul > li > button + div.transition {
  overflow: auto;
}

.style__tab > ul > li > button + div.transition > .content {
  overflow-y: scroll;
  width: 285px;
}

/* -- 텍스트 추가 1단계 팝업 */
.text_group {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.text_group button:first-child {
  width: 273px;
  height: 50px;
  background: #f9f9f9;
  border-radius: 8px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1a2032;
  vertical-align: middle;
}
.text_group button:first-child:hover {
  color: #7b61ff;
}
.text_group button:nth-child(2) {
  width: 273px;
  height: 50px;
  background: #f9f9f9;
  border-radius: 8px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1a2032;
  margin: 12px 0;
}
.text_group button:nth-child(2):hover {
  color: #7b61ff;
}
.text_group button:nth-child(3) {
  width: 273px;
  height: 50px;
  background: #f9f9f9;
  border-radius: 8px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1a2032;
}
.text_group button:nth-child(3):hover {
  color: #7b61ff;
}
.text_group button:first-child span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: -0.03em;
  color: #acafc0;
  margin-left: 85px;
  vertical-align: middle;
}
.text_group button:nth-child(2) span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: -0.03em;
  color: #acafc0;
  margin-left: 85px;
  vertical-align: middle;
}
.text_group button:nth-child(3) span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: -0.03em;
  color: #acafc0;
  margin-left: 130px;
  vertical-align: middle;
}
.text__content .script_btn {
  box-sizing: border-box;
  width: 273px;
  height: 50px;
  border: 1px solid #7b61ff;
  border-radius: 8px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #7b61ff;
  margin-top: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.script_btn_icon {
  background-image: url(../images/backgroundImg/script-icon.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin-left: 40px;
}

/* -- 텍스트 추가 1단계 팝업 */

.style__tab .text__content .text__textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 77px;
  min-height: 77px;
  max-height: 77px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 7px 12px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 400;
  color: #1a2032;
  line-height: 1.75;
  margin-bottom: 20px;
  transition: 0.3s ease;
}
.style__tab .text__content .text__textarea:focus {
  border: 1px solid #7b61ff;
  background-color: #f9fafc;
}
.style__tab .text__content .select__cont > span {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Spoqa Han Sans Neo';
  color: #a3aab8;
}
.style__tab .text__content .col {
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f3f5;
}
.style__tab .text__content .col:last-child {
  border-bottom: none;
}
.style__tab .font__style__cont {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.style__tab .font__style__cont input {
  width: 50px;
  height: 32px;
  border-radius: 10px;
  background-color: #fafbfd;
  box-shadow: inset 0px -1px 10px rgba(0, 0, 0, 0.03);
  border: none;
}
.style__tab .font__style__cont > div {
  align-items: center;
}
.style__tab .font__style__cont > div .font__style__kind {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
}

.style__tab .font__style__cont > div span {
  font-size: 12px;
  font-weight: 400;
  color: #acafc0;
  padding-top: 1px;
}
.style__tab .font__style__cont > div input {
  padding-left: 10px;
  text-align: center;
}
.style__tab .color__basic {
  padding-top: 28px;
}
.style__tab .color__basic .flex {
  gap: 10px;
  align-items: center;
}
.style__tab .color__basic span {
  font-size: 12px;
  font-weight: 500;
  color: #a3aab8;
}
.style__tab .color__basic p {
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: 600;
  color: #363d59;
}
.style__tab .color__basic .color__list {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 12px;
  margin-bottom: 22px;
}
.style__tab .color__basic .color__list button {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: relative;
}
.style__tab .color__basic .color__list button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid #e9e9e9;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}
.style__tab .color__basic .color__list button::after {
  content: '';
  width: 7.5px;
  height: 7.5px;
  background: url('../images/icons/studio-side/ico-check-white.svg') no-repeat
    center / cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}
.style__tab .color__basic .color__list button.active::before {
  opacity: 1;
  visibility: visible;
}
.style__tab .color__basic .color__list button.active::after {
  opacity: 1;
  visibility: visible;
}
.style__tab .font__style__list {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background-color: #fafbfd;
  padding: 5px;
  display: flex;
  align-items: center;
  box-shadow: inset 0px -1px 10px rgba(0, 0, 0, 0.03);
}
.style__tab .font__style__list > button,
.style__tab .font__style__list > div > button {
  width: 45px;
  height: 30px;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.style__tab .font__style__list > button::before,
.style__tab .font__style__list > div > button::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
}
.style__tab .font__style__list > button::after,
.style__tab .font__style__list > div > button::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #363d59;
  opacity: 0.09;
  filter: blur(4px);
  border-radius: 8px;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
}
.style__tab .font__style__list > button.active::before,
.style__tab .font__style__list > div > button.active::before {
  opacity: 1;
  visibility: visible;
}
.style__tab .font__style__list > button.active::after,
.style__tab .font__style__list > div > button.active::after {
  opacity: 0.09;
}

.style__tab .font__style__list > button img,
.style__tab .font__style__list > div > button img {
  position: relative;
  z-index: 10;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 4px;
}
.style__tab .text__line__box::before {
  content: '';
  width: 7px;
  height: 7px;
  background-color: #353539;
  position: absolute;
  top: -3.5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.style__tab .text__line__box {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  position: absolute;
  left: 50%;
  top: calc(100% + 4px);
  transform: translateX(-50%);
  width: 136px;
  /* height: 87px; */
  border-radius: 10px;
  background-color: #353539;
  padding: 12px 10px;
}
.style__tab .font__style__list .relative:hover .text__line__box {
  opacity: 1;
  visibility: visible;
}
.style__tab .text__line__box button.active::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -4px;
  width: 1px;
  height: 14px;
  border-radius: 2px;
  background-color: #9a88f7;
}
.style__tab .text__line__box button {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
  position: relative;
}
.style__tab .text__line__box button:last-child {
  margin-bottom: 0;
}
.style__tab .font__style__list button.color__picker__btn {
  /* border-left: 1px solid #e9e9e9; */
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  padding-left: 12px;
  position: relative;
}
.style__tab .font__style__list button.color__picker__btn::before {
  content: '';
  width: 1px;
  height: 14px;
  background-color: #e9e9e9;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
}
.style__tab .font__style__list button.color__picker__btn::after {
  display: none;
}

.translate {
  height: 367px !important;
  display: none;
}
.translate.active {
  display: block;
}
.translate-select-cont {
  width: 272px;
  height: auto;
  margin-top: 30px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.translate-select-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.translate-select-box {
  height: 50px;
  border: 1.5px solid #e9e9e9;
  border-radius: 8px;
  width: 100%;
  position: relative;
  padding: 0 18px;
  cursor: pointer;
}
.translate-select-box span {
  font-weight: 400;
  font-size: 14px;
  color: #1a2032;
}
.translate .edit-change {
  margin-top: 30px;
}
.tranalate-select-desc {
  width: 178px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #9194a4;
  text-align: center;
  padding: 18px 20px;
  position: absolute;
  top: -63px;
  right: -46px;
}
.translate .nextBtn {
  width: 123px !important;
  bottom: 26px !important;
  position: absolute !important;
  right: 22px !important;
  top: unset;
  margin-top: 30px;
}
.translate-select-inner {
  width: 100%;
  max-height: 220px;
  position: absolute;
  background: #ffffff;
  border: 1px solid #dfe0e5;
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 53px;
  padding: 10px;
  z-index: 99;
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 1;
  /* visibility: hidden; */
}
.translate-select-inner.active {
  opacity: 1;
  visibility: visible;
}
.translate-select-button .translate-arrow {
  width: 16px;
  height: 14px;
  transition: 0.3s ease;
}
translate-select-inner.active .translate-arrow {
  transform: rotate(180deg);
}
.translate-select-inner ul {
  display: flex;
  height: auto !important;
  flex-direction: column;
}
.translate-select-inner ul li {
  width: 100% !important;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  color: #1a2032;
  gap: 25px;
  display: flex;
  align-items: center;
  padding-left: 14px;
  transition: 0.3s ease;
  cursor: pointer;
}
.translate-select-inner span {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  color: #1a2032;
}
.translate-select-inner p {
  max-width: 173px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #1a2032;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.translate-select-inner ul li:hover {
  background: #f8f7ff;
  border-radius: 5px;
}
.translate-select-inner ul li:hover .countryIcon {
  opacity: 1;
}
.translate-select-inner .countryIcon {
  opacity: 0.35;
  transition: 0.3s ease;
}
.translate-select-inner ul li h3 {
  font-weight: 400;
  font-size: 14px;
  color: #1a2032;
}
.translate-expert-popup {
  width: 442px;
  height: auto;
  background: #ffffff;
  border-radius: 20px;
  padding: 26px;
  position: fixed;
  top: 17%;
  left: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  transform: translateX(-26%);
}

.translate-expert-popup .title h2 {
  font-weight: 700;
  font-size: 16px;
  color: #1a2032;
  display: inline-block;
}
.translate-btn {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #f2f3f5;
  float: right;
}
.translate-expert-cont {
  /* margin-top: 30px; */
  display: flex;
  gap: 30px;
  flex-direction: column;
  text-align: left;
}
.translate-expert-cont .col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
.translate-expert-cont .col > div {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.translate-expert-cont .col span {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.translate-expert-cont .col input {
  width: 390px;
  height: 50px;
  border: 0;
  background: #f7f7f8;
  border-radius: 8px;
  padding: 0 20px;
}
.translate-expert-cont .col input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.input-border {
  background: #ffffff !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
}
.expert-btn-cont {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  height: 50px;
}
.gray-cancel {
  width: 136px !important;
  height: 100%;
  background: #f2f3f5;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #1a2032;
}
.purple-next {
  width: 100%;
  background: #7b61ff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.icon-download {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.translate-expert-cont p {
  margin-top: 14px;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  color: #1a2032;
}
.translate-expert-cont strong {
  font-family: 'Montserrat', sans-serif;
}
.expert-coin {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  color: #1a2032;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -22px;
}
.expert-coin img {
  margin-top: -35px;
}
.translate-coin-cont {
  margin-top: 24px;
  width: 100%;
}
.translate-coin-cont .coin-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.translate-coin-cont .coin-cont:nth-of-type(2) {
  content: '';
  min-width: 390px;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
  padding: 8px 0 15px 0;
}
.translate-coin-cont .coin-cont span {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.coin {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.coin strong {
  font-weight: 600;
}
.style__tab .translate .content > div .list {
  height: auto;
}
.coin-charge {
  width: 36px !important;
  height: 24px;
  background: linear-gradient(270.15deg, #b635f5 0%, #6dd1f0 100%);
  border-radius: 5px;
  font-weight: 500;
  font-size: 11px;
  color: #ffffff;
  position: absolute;
  left: 114px;
}
.minus-coin {
  width: 8px;
  height: 0;
  border: 1px solid #666666;
  position: absolute;
  right: 74px;
}
/* .translate-request {
  margin-top: -30px;
} */
.translate-request h3 {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 23px;
  color: #1a2032;
}
.translate-request p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #9194a4;
}
.translate-icon {
  width: 138px;
  height: 138px;
  margin: 0 auto;
  display: block;
}
/* style__tab content end */

/* MetahumanPopup */
.meta-popupCont {
  position: fixed;
  top: 10%;
  left: 60%;
  transform: translateX(-60%);
  max-width: 728px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.05);
  min-height: 560px;
}
.meta-popupCont .popup-top {
  height: 66px;
  display: flex;
  justify-content: space-between;
  padding: 26px;
}
.meta-popupCont .popup-top span {
  font-size: 16px;
  line-height: 20px;
  color: #1a2032;
  font-weight: 700;
}
.meta-popupCont .meta-popup-inner {
  width: 100%;
  height: auto;
}
.meta-popupCont .meta-popup-inner .meta-ul {
  display: flex;
  flex-direction: column;
}
.meta-popupCont .meta-popup-inner .meta-ul .meta-li {
  background: #ffffff;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.05);
  height: auto;
  /* padding: 0 26px; */
}
.accordion-select {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  position: relative;
  height: 66px;
  padding: 0 26px;
}
.meta-li span {
  font-size: 14px;
  font-weight: 400;
  color: #7b61ff;
  line-height: 17px;
  font-family: 'Montserrat';
}
.meta-li strong {
  font-size: 14px;
  color: #222222;
}
.meta-li strong.active {
  opacity: 0.6;
}
.meta-li span.active {
  opacity: 0.6;
}
.popup__pencil__icon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 26px;
  background-image: url(../images/icons/common/icon-pencil.svg);
}
.meta-popup-btn {
  height: 66px;
  position: relative;
}
.meta-popup-complete {
  background-color: #7b61ff;
  opacity: 0.6;
  height: 40px;
  width: 98px;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  position: absolute;
  right: 26px;
  top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
}
.meta-popup-complete.active {
  opacity: 1;
}
.accordin-menu {
  width: 100%;
  height: auto;
}
.menu-human {
  height: 360px;
  overflow-y: scroll;
}
.accordin-menu-human {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 28px;
  height: 100%;
  padding: 0 26px;
}
.accordin-menu-human .human-box {
  min-width: 206px;
  height: 100%;
  border-radius: 10px;
}
.humanImg {
  width: 100%;
  min-height: 143px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  padding: 12px 15px 0 12px;
}
.humanImg.active {
  border: 2px solid #7b61ff;
}
.human01 {
  background-image: url(../images/metahuman/01.png);
}
.human02 {
  background-image: url(../images/metahuman/02.png);
}
.human03 {
  background-image: url(../images/metahuman/03.png);
}
.badge {
  display: flex;
  gap: 6px;
}
.badgeFree {
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  background: #7b61ff;
  border-radius: 3px;
  width: 35px;
  height: 18px;
  display: block;
  font-family: 'Montserrat';
  text-align: center;
}
.badgeReal {
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #7b61ff;
  color: #7b61ff;
  border-radius: 3px;
  width: 35px;
  height: 18px;
  display: block;
  font-family: 'Montserrat';
  text-align: center;
}
.popup__lock__icon {
  background-image: url(../images/metahuman/icon-lock.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 15px;
  top: 12px;
}
.lock-popup.active {
  opacity: 1;
  visibility: visible;
}
.lock-popup {
  width: 442px;
  height: 223px;
  background-color: #ffffff;
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px -1px 10px rgb(0 0 0 / 5%);
  padding: 16px 32px 28px 32px;
  transition: 0.3s ease;
}
.lockBtn {
  position: absolute;
  right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #f2f3f5;
}
.lock-popup span {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #1a2032;
  margin-top: 41px;
  display: block;
}
.lock-popup p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #9194a4;
  margin-top: 6px;
}
.human-box h3 {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #1a2032;
  line-height: 20px;
}
.storeGo-btn {
  width: 100%;
  height: 50px;
  background: #7b61ff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin-top: 39px;
}
.human-box p {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #acafc0;
}

.accordin-menu-style {
  width: max-content;
  height: 125px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: auto;
  padding-left: 26px;
}
.accordin-menu-style .style-li {
  width: 80px;
  height: 100%;
}
.style-scroll {
  overflow-x: auto;
  padding-right: 26px;
}
.style-li .style-box {
  width: 100%;
  height: 80px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.style-li h3 {
  font-size: 12px;
  font-weight: 400;
  color: #5e6479;
  margin-top: 4px;
}
.style-box.active {
  border: 2px solid #7b61ff;
}

.accordin-menu-voice {
  width: max-content;
  height: 77px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: auto;
  padding-left: 26px;
}
.voice-li {
  width: auto;
  height: 54px;
  padding: 12px 16px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 14px;
}
.voice-li.active {
  border: 2px solid #7b61ff;
}
.voice-li h3 {
  font-size: 12px;
  color: #5e6479;
  font-weight: 400;
}
.voice-li button {
  width: 30px;
  height: 30px;
  background-image: url(../images/metahuman/mata-voice-button.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.voice-li button.active {
  background-image: url('../images/icons/common/ico-stop-purple.svg');
  background-position: center;
  background-size: 15px;
  background-repeat: no-repeat;
}

.accordin-menu-script {
  height: 116px;
}
.accordin-menu-script form {
  position: relative;
}
.accordin-menu-script textarea {
  max-width: 676px;
  width: 100%;
  height: 100px;
  border: none;
  margin: 0px auto 0 auto;
  display: block;
}
.accordin-menu-script .text-byte {
  font-size: 11px;
  font-weight: 500;
  color: #adadad;
  display: flex;
  position: absolute;
  right: 26px;
}
.accordin-menu-script form textarea::placeholder {
  font-family: 'Spoqa Han Sans Neo', 'Montserrat';
  text-indent: 7px;
  font-size: 14px;
}

.meta-area-wright form textarea::placeholder {
  font-family: 'Spoqa Han Sans Neo', 'Montserrat';
  text-indent: 7px;
  font-size: 14px;
}
.script-select-cont {
  width: 100%;
  height: 58px;
  background-color: #f9f9f9;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px;
}
.script-select-cont > div {
  position: relative;
}
.script-select-btn {
  position: absolute;
  left: 188px;
  width: 30px;
  height: 30px;
  background-image: url(../images/metahuman/mata-voice-button.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.1s ease-out;
}

.script-select-btn.active {
  background-image: url('../images/icons/common/ico-stop-heroicon.svg');
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  background-color: #7b61ff;
  border-radius: 50%;
  color: white;
}

.script-select-btn:hover {
  opacity: 0.7;
}

.script-select {
  height: 30px;
  background-color: #ededed;
  border-radius: 5px;
  padding: 0 13px;
  display: flex;
  gap: 6px;
  align-items: center;
  position: relative;
}
.select-154 {
  width: 154px;
}
.select-194 {
  width: 194px;
}
.scriptIcon01 {
  background-repeat: no-repeat;
  background-image: url(../images/metahuman/icon-script01.svg);
  width: 16px;
  height: 14px;
}
.scriptIcon02 {
  background-repeat: no-repeat;
  background-image: url(../images/metahuman/icon-script02.svg);
  width: 16px;
  height: 16px;
}
.script-select p {
  font-weight: 400;
  font-size: 14px;
  color: #1a2032;
}
.scriptArrow {
  position: absolute;
  right: 13px;
  transition: 0.3s ease;
}
.script-wrap::-webkit-scrollbar {
  width: 4px;
}
.script-wrap::-webkit-scrollbar-thumb {
  background-color: #eeeef0;
  border-radius: 17px;
}
.script-wrap ul {
  display: flex;
  flex-direction: column;
}
.script-wrap ul li button:hover {
  background: #f8f7ff;
}
.script-wrap ul li button:hover .countryIcon {
  opacity: 1;
}
.script-wrap ul li button {
  font-weight: 500;
  font-size: 14px;
  color: #1a2032;
  width: 100%;
  height: 100%;
  padding: 14px 30px 14px 27px;
  text-align: left;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.script-wrap {
  width: 100%;
  /* height: 188px; */
  background-color: #ffffff;
  border-radius: 5px;
  position: absolute;
  top: 35px;
  padding: 5px 0px 5px 5px;
  overflow-y: scroll;
  z-index: 1;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.05);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}
.script-select.active .script-wrap {
  opacity: 1;
  visibility: visible;
}
.script-select.active .scriptArrow {
  transform: rotate(-180deg);
}
.script-wrap img {
  opacity: 0.36;
  transition: 0.3s ease;
}

.selectAfter-icon {
  width: auto;
  height: 29px;
  padding: 4px 12px;
  background-color: #7b61ff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.selectAfter-img {
  width: 21px;
  height: 21px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/metahuman/selectAfter.png);
  border-radius: 100%;
}
.selectAfter-icon h3 {
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
}
.script_btn .script-select-desc {
  width: 178px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #9194a4;
  text-align: center;
  padding: 18px 20px;
  position: absolute;
  top: 364px;
  right: -12px;
}
/* MetahumanPopup */

/* studio content box */
.studio__main .content__line {
  padding-top: 2px;
  width: 100%;
  /* padding-right: 10px; */
  max-height: 100vh;
  margin-top: 0.5px;
}
.studio__main .content__line li {
  height: 40px;
  margin-bottom: 2px;
  display: flex;
}
.studio__main .content__line li > div[class*='__box'] {
  position: relative;
  margin-right: 2px;
}
.studio__main .content__line li > div[class*='__box']:hover,
.studio__main .content__line li > div[class*='__box'].active {
  outline: 2px solid #7b61ff;
}
.studio__main .content__line li > div[class*='__box'].active .left__size__btn,
.studio__main .content__line li > div[class*='__box'].active .right__size__btn {
  display: flex;
}
.studio__main .content__line li > div[class*='__box'] ?.fadeIn {
  background: url('../images/icons/studio-timeline/ico-fade-black.svg')
    no-repeat center / cover;
  height: 20px;
  width: 20px;
  transform: translateY(-50%) scaleX(-1);
  position: absolute;
  left: 4px;
  top: 50%;
}
.studio__main .content__line li > div[class*='__box'] ?.fadeOut {
  background: url('../images/icons/studio-timeline/ico-fade-black.svg')
    no-repeat center / cover;
  height: 20px;
  width: 20px;
  transform: translateY(-50%);
  position: absolute;
  right: 4px;
  top: 50%;
}
.studio__main .content__line li > div[class*='__box'] .left__size__btn {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 2px;
  z-index: 10;
  width: 4px;
  height: 10px;
  display: none;
}
.studio__main .content__line li > div[class*='__box'] .right__size__btn {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 2px;
  z-index: 10;
  width: 4px;
  height: 10px;
  display: none;
}
.studio__main .content__line li > div[class*='__box'] .right__size__btn span,
.studio__main .content__line li > div[class*='__box'] .left__size__btn span {
  border-radius: 2px;
  width: 1px;
  height: 100%;
  background-color: #7b61ff;
}
.studio__main .content__line li > div[class*='__box'] .timeline__fadeOut__icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%) scaleX(1);
  z-index: 0;
}
.studio__main .content__line li > div[class*='__box'] .timeline__fadeIn__icon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%) scaleX(-1);
  z-index: 0;
}

.studio__main .content__line .script__box {
  height: 100%;
  background-color: #363d59;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  gap: 8px;
  border-radius: 4px;
  position: relative;
}
.studio__main .content__line .script__box.transition .transition {
  content: '';
  width: 16px;
  height: 16px;
  background: url('../images/icons/common/ico-transition.svg') no-repeat center /
    cover;
  display: block;
  position: absolute;
  right: -10px;
  top: 50%;
  z-index: 100;
  transform: translateY(-50%);
  cursor: pointer;
}

.studio__main .content__line .script__box.transition .transition.active {
  background: url('../images/icons/common/ico-transitionNot.svg') no-repeat
    center / cover;
}
/* .studio__main .content__line .script__box.notTransition::after {
  content: '';
  width: 16px;
  height: 16px;
  background: url('../images/icons/common/ico-transitionNot.svg') no-repeat
    center / cover;
  display: block;
  position: absolute;
  right: -10px;
  top: 50%;
  z-index: 100;
  transform: translateY(-50%);
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  cursor: pointer;
} */

.studio__main .content__line .script__box .metahuman__img {
  min-width: 28px;
  min-height: 28px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.studio__main .content__line .script__box .script__text {
  font-size: 11px;
  font-weight: 400;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.studio__main .content__line .backgroundImg__box {
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: #f6f7f9;
  overflow: hidden;
  position: relative;
}
.studio__main .content__line .backgroundImg__box:hover::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

.studio__main .content__line .backgroundImg__box .backgroundImg__img {
  width: auto;
  height: 100%;
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: center;
}

.studio__main .content__line .backgroundVideo__box {
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: #f6f7f9;
  position: relative;
}
.studio__main .content__line .backgroundVideo__box:hover:after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

.studio__main
  .content__line
  .backgroundVideo__box
  i.timeline__backgroundVideo__icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}
.studio__main .content__line .backgroundVideo__box .video__img {
  width: max-content;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}
.studio__main .content__line .backgroundVideo__box .video__img .videoImg__img {
  width: auto;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.studio__main .content__line .backgroundVideo__box .backgroundVideo__img {
  width: auto;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.studio__main .content__line .backgroundMusic__box {
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: #f8f7ff;
  padding: 0 10px;
  gap: 8px;
}
.studio__main .content__line .backgroundMusic__box i {
  min-width: 12px;
  min-height: 12px;
}

.studio__main .content__line .backgroundMusic__name {
  font-size: 11px;
  font-weight: 400;
  color: #1a2032;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.studio__main .content__line .text__box {
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: #363d59;
  padding: 0 10px;
  gap: 8px;
}
.studio__main .content__line .text__box i {
  min-width: 15px;
  min-height: 15px;
}
.studio__main .content__line .text__box .text__desc {
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.studio__main .content__line .soundEffect__box {
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: #363d59;
  padding: 0 10px;
  gap: 8px;
}

.studio__main .video__box {
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: #f6f7f9;
  position: relative;
}
.studio__main .video__box:hover::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.studio__main .video__box i.timeline__video__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 0;
}
.studio__main .video__box .video__img {
  width: max-content;
  height: 100%;
  overflow: hidden;
}
.studio__main .video__box .video__img .videoImg__img {
  width: auto;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.studio__main .img__box {
  height: 40px;
  background-color: #f8f7ff;
  border-radius: 4px;
  padding: 7px 0;
  position: relative;
}
.studio__main .img__box:hover::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.studio__main .img__box .deco__img__group {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}
.studio__main .img__box .deco__img__group img {
  height: 100%;
  width: auto;
}

.studio__main .content__line img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.studio__main .content__line .fx {
  position: relative;
}
.studio__main .content__line .fx__box {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 10;
}
.studio__main .content__line .soundEffect__box i {
  min-width: 12px;
  min-height: 12px;
}
.studio__main .content__line .soundEffect__box .soundEffect__name {
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.studio__main .content__line .fx__box:hover .fx__content {
  opacity: 1;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
  bottom: -10px;
  left: 30px;
  visibility: visible;
  margin-left: 30px;
}
.studio__main .content__line .fx__box .fx__content {
  position: absolute;
  margin-left: 30px;
  bottom: calc(100% + 14px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 88px;
  height: 75px;
  border-radius: 5px;
  padding: 8px;
  opacity: 0;
  visibility: hidden;
}
.studio__main .content__line .fx__box .fx__content:before {
  margin-left: 30px;
  content: '';
  width: 100%;
  height: 20px;
  background-color: transparent;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.studio__main .content__line .fx__box .fx__content::after {
  margin-left: 30px;
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 1px;
  background-color: #fff;
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.studio__main .content__line .fx__box .fx__content .col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}
.studio__main .content__line .fx__box .fx__content .col:last-child {
  margin-bottom: 0;
}
.studio__main .fx__box .fx__content .col input[type='checkbox'] {
  display: none;
}
.studio__main .fx__box .fx__content .col input[type='checkbox'] + label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.studio__main .fx__box .fx__content .col input[type='checkbox'] + label p {
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #363d59;
}

.studio__main
  .fx__box
  .fx__content
  .col
  input[type='checkbox']
  + label
  .checkbox {
  width: 10px;
  height: 10.4px;
  border-radius: 2px;
  outline: 1px solid #363d59;
  position: relative;
}
.studio__main
  .fx__box
  .fx__content
  .col
  input[type='checkbox']
  + label
  .checkbox::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 4px;
  background: url('../images/icons/common/ico-check-black.svg') no-repeat center /
    cover;
  display: none;
}
.studio__main
  .fx__box
  .fx__content
  .col
  input[type='checkbox']:checked
  + label
  .checkbox::before {
  display: block;
}

.studio__main .fx__box .fx__content .col .sound__range {
  width: 50px;
}
.studio__main .fx__box .fx__content .sound__range {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  height: 10px;
  width: 90px;
  cursor: pointer;
  border-radius: 0; /* iOS */
}
.studio__main .fx__box .fx__content .sound__range {
  /* background: linear-gradient(to right, #B635F5 0%, #6DD1F0 50%, #E9E9E9 50%, #E9E9E9 100%); */
  border-radius: 8px;
  height: 2px;
  width: 90px;
  outline: none;
  background: linear-gradient(
    to right,
    #7b61ff 0%,
    #7b61ff 50%,
    #e9e9e9 50%,
    #e9e9e9 100%
  );
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.studio__main .fx__box .fx__content .sound__range::-webkit-slider-thumb {
  background-color: #fff;
  border: solid 3px #7b61ff;
  border-radius: 100%;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  -webkit-appearance: none;
}
.studio__main .fx__box {
  opacity: 0;
  visibility: hidden;
}
.studio__main .fx .fx__box {
  opacity: 1;
  visibility: visible;
  position: absolute;
  background-color: #fff;
}
.studio__main .fx .fx__box .fx__icon {
  position: relative;
  z-index: 10;
}
.studio__main .fx .fx__box:hover .fx__icon {
  background-image: url('../images/icons/common/ico-fx-active.svg');
}
.studio__main .fx .fx__box::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(173, 173, 173, 0.2);
  filter: blur(4px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.style__tab .textChoice__content > button:not(.script__auto__btn) {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  padding: 0 16px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
  margin-bottom: 10px;
}
.style__tab .textChoice__content > button:not(.script__auto__btn) span {
  font-size: 14px;
  font-weight: 400;
  color: #acafc0;
  font-family: 'Montserrat';
}
.style__tab .textChoice__content > button:not(.script__auto__btn):hover {
  background: rgba(123, 97, 255, 0.05);
}
.style__tab .textChoice__content > button:not(.script__auto__btn):hover h2 {
  color: #7b61ff;
}

.style__tab .textChoice__content > button.title__btn {
  background-color: #f9f9f9;
  transition: 0.3s ease;
}
.style__tab .textChoice__content > button.title__btn h2 {
  font-size: 25px;
  font-weight: 600;
  color: #1a2032;
  transition: 0.3s ease;
}

.style__tab .textChoice__content > button.subTitle__btn {
  background-color: #f9f9f9;
  transition: 0.3s ease;
}
.style__tab .textChoice__content > button.subTitle__btn h2 {
  font-size: 20px;
  font-weight: 500;
  color: #1a2032;
  transition: 0.3s ease;
}

.style__tab .textChoice__content > button.text__btn {
  background-color: #f9f9f9;
  transition: 0.3s ease;
}
.style__tab .textChoice__content > button.text__btn h2 {
  font-size: 14px;
  font-weight: 400;
  color: #1a2032;
  transition: 0.3s ease;
}
.style__tab .textChoice__content > button:last-child {
  margin-top: 40px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #7b61ff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  padding-right: 16px;
  color: #7b61ff;
  transition: 0.3s ease;
}
.style__tab .textChoice__content > button:last-child:hover {
  background-color: #f5f3ff;
}

.style__tab .textChoice__content > button:last-child span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.style__tab .textChoice__content > button:last-child .script__auto__info__text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 4px);
  width: 178px;
  padding: 18px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  font-size: 12px;
  font-weight: 400;
  color: #9194a4;
  line-height: 1.75;
  opacity: 0;
  visibility: hidden;
  transition: 0.24s ease;
}
.style__tab .textChoice__content > button:last-child .script__auto__info {
  position: relative;
}
.style__tab
  .textChoice__content
  > button:last-child
  .script__auto__info:hover
  .script__auto__info__text {
  opacity: 1;
  visibility: visible;
}

/* studio content box end */

/* @pcw 추가 */

.w-full {
  /* margin-left: 135px; */
}

.timeline-area {
  /* height: calc(40vh - 135px); */
  /* height: calc(40vh - 135px); */
  /* position: fixed; */
  bottom: 0px;
  height: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: calc(40vh - 135px);
}

.test {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.test::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.horizontal-slider {
  width: 100%;
  width: 81900px;
  /* height: 100vh; */
  z-index: 100000000;
}

.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #e45757;
  border-radius: 100%;
  display: block;
  /* box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%); */
}

.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}
.studio__timeline .time__box .time__progress2 {
  position: absolute;
  top: -2px;
  left: 136px;
  width: calc(100% - 136px);
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding-right: 27px;
}
.studio__timeline .time__box .time__progress2 span {
  width: 2px;
  height: 10px;
  background-color: #e9e9e9;
  display: block;
  position: relative;
}
.studio__timeline .time__box .time__progress2 span em {
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 600;
  color: #363d59;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  font-style: normal;
}

.ppt__btn {
  width: 90px;
  height: 30px;
  margin: 0 auto;
  background-color: transparent;
  padding-top: 5px;
}
.pdf__btn {
  width: 90px;
  height: 30px;
  margin: 0 auto;
  background-color: transparent;
  padding-top: 5px;
}
.pdf__btn label {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  color: #363d59;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  margin-left: 16px;
  padding-top: 8px;
}

.ppt__btn label {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  color: #363d59;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  margin-left: 16px;
  padding-top: 8px;
}
.pdf__btn:hover {
  width: 90px;
  height: 30px;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #f8f7ff;
  padding-top: 5px;
}
.ppt__btn:hover {
  width: 90px;
  height: 30px;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #f8f7ff;
  padding-top: 5px;
}
.pdf__btn input[type='file'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  border: 0;
}
.ppt__btn input[type='file'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  border: 0;
}

/* .filebox label {
  display: inline-block;
  padding: .5em .75em;
  color: #999;
  font-size: inherit;
  line-height: normal;
  vertical-align: middle;
  background-color: #fdfdfd;
  cursor: pointer;
  border: 1px solid #ebebeb;
  border-bottom-color: #e2e2e2;
  border-radius: .25em;
}

.filebox input[type="file"] {  
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip:rect(0,0,0,0);
  border: 0;
} */

/* color picker */
.sketch-picker {
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 auto !important;
  width: 280px !important;
  max-width: 316px !important;
}
.sketch-picker > div:nth-child(1) {
  border-radius: 20px;
  padding: 0 !important;
  width: 100%;
  height: 134px;
}

.sketch-picker > div:nth-child(2) {
  /* border: 1px solid green; */
  margin: 10px auto;
}

.sketch-picker > div:nth-child(2) div:nth-child(1) {
  border-radius: 20px;
}

.sketch-picker > div:nth-child(2) div:nth-child(2) {
  border-radius: 20px;
}

.sketch-picker > div:nth-child(2) > div:nth-child(2) {
  border: 2px solid red;
  display: none;
}

.sketch-picker > div:nth-child(3) {
}

.sketch-picker > div:nth-child(3) > div:nth-child(1) > div {
  width: 75px !important;
  margin: 0 auto;
}

.sketch-picker > div:nth-child(3) > div:nth-child(1) > div > input {
  border: none !important;
  width: 75px !important;
  height: 34px;
  background: #f6f7f9;
  border-radius: 7px;
  text-align: center;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #1a2032;
}

.sketch-picker > div:nth-child(3) > div:nth-child(1) > div > label {
  text-align: center !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13px !important;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #1a2032 !important;
  margin: 8px auto;
}

.sketch-picker > div:nth-child(3) > div:nth-child(2) > div > input {
  width: 45px !important;
  height: 34px;
  background: #ffffff;
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 7px;
  text-align: center;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
}

.sketch-picker > div:nth-child(3) > div:nth-child(2) > div > label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #adadad !important;
  margin: 8px auto;
  text-align: center !important;
}

.sketch-picker > div:nth-child(3) > div:nth-child(3) > div > input {
  width: 45px !important;
  height: 34px;
  background: #ffffff;
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 7px;
  text-align: center;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
}

.sketch-picker > div:nth-child(3) > div:nth-child(3) > div > label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #adadad !important;
  margin: 8px auto;
  text-align: center !important;
}

.sketch-picker > div:nth-child(3) > div:nth-child(4) > div > input {
  width: 45px !important;
  height: 34px;
  background: #ffffff;
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 7px;
  text-align: center;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #000000;
}

.sketch-picker > div:nth-child(3) > div:nth-child(4) > div > label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #adadad !important;
  margin: 8px auto 15px auto;
  text-align: center !important;
}

.sketch-picker > div:nth-child(3) > div:nth-child(5) > div > input {
  width: 45px !important;
  height: 34px;
  background: #ffffff;
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 7px;
  text-align: center;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #999999 !important;
}

.sketch-picker > div:nth-child(3) > div:nth-child(5) > div > label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #adadad !important;
  margin: 8px auto;
  text-align: center !important;
}

.sketch-picker > div:nth-child(4) div {
  width: 36px !important;
  height: 36px !important;
  border-radius: 8px !important;
  margin: 0 10px 8px 0 !important;
}

.color_btn {
  width: 272px;
  height: 50px;
  align-items: center;
  padding: 17px 69px;

  background: #7b61ff;
  opacity: 0.7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 12px auto;

  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  letter-spacing: -0.03em;

  color: #ffffff;
}

.color_btn:hover {
  opacity: 1;
}

.text_wrap {
  width: 265px;
  height: 50px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
}

.text_wrap .img__text {
  display: flex;
  justify-content: left;
  padding: 15px !important;

  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #1a2032 !important;
}

.text_wrap:hover {
  background: #f8f7ff;
  /* border: 1px solid #7b61ff; */
}

.color_picker_wrap {
  padding: 50px !important;
  position: absolute;
  top: 8px;
  right: 266px;
}

.color_picker_wrap h4 {
  position: relative;
  top: 38px;
  left: 20px;
  width: 100px;

  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #1a2032;
}

.color_picker_wrap .picker_close {
  position: relative;
  top: 56px;
  left: 280px;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}

.color_picker_wrap .sketch-picker {
  padding: 55px 20px 10px 20px !important;
  border-radius: 8px !important;
  border: 0.5px solid #e9e9e9;
}

/* 07-18 대화형 스크립트 추가 */
.meta-area-script {
  overflow-y: auto;
  height: 225px;
  background-color: #f8f7ff;
  padding: 5px 0 18px;
}
.meta-area-script .script-box {
  margin: 35px 16px 35px 32px;
  display: flex;
  align-items: flex-start;
}
.meta-area-script .script-box.type-right {
  flex-direction: row-reverse;
}
.meta-area-script .script-box .script-profile {
  position: relative;
}
.meta-area-script .script-box .profile-name {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
}
.meta-area-script .profile-name .txt-name {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: #fff;
}
.meta-area-script .script-box .delet-script {
  display: none;
  position: absolute;
  top: -6px;
  left: -12px;
  padding: 6px;
}
.meta-area-script .script-box.type-right .delet-script {
  left: auto;
  right: -12px;
}
.meta-area-script .script-box:hover .delet-script {
  display: block;
}
.meta-area-script .script-box .area-script {
  padding: 14px 16px 18px;
  margin-left: 6px;
  background-color: #fff;
  border-radius: 0 20px 20px 20px;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.03em;
}
.meta-area-script .script-box.type-right .area-script {
  border-radius: 20px 0 20px 20px;
  margin: 0 6px 0 0;
}
.meta-area-wright {
  padding: 24px 26px 16px;
}
.meta-area-wright textarea {
  width: 100%;
  height: 100px;
  font-size: 14px;
  line-height: 25px;
}
.meta-area-wright .text-byte {
  font-size: 11px;
  color: #adadad;
  text-align: right;
}
.meta-area-wright .text-byte p {
  display: inline;
}
.script-select-cont.type-add-btn {
  justify-content: flex-start;
}
.script-select-cont .select-inner {
  display: flex;
  gap: 4px;
}
.script-select-cont .select-add-btn {
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #ededed;
  text-align: center;
  padding: 0 22px;
  margin-left: auto;
}

.script-select-cont .select-add-btn:hover {
  background-color: rgba(237, 237, 237, 0.8);
}

.script-select-cont .script-select-btn-talk {
  background-image: url(../images/metahuman/mata-voice-button.png);
  background-position: center;
  background-size: cover;
  transition: 0.1s ease-out;
  width: 30px;
  height: 30px;
}

.script-select-btn-talk.active {
  background-image: url('../images/icons/common/ico-stop-heroicon.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  background-color: #7b61ff;
  color: white;
  border-radius: 50%;
}
.meta-popup-btn.type-more-btn {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 26px;
}
.meta-popup-btn.type-more-btn .meta-popup-complete {
  position: static;
}
.meta-popup-btn .meta-popup-prev {
  opacity: 1;
  color: #1a2032;
  background-color: #f2f3f5;
}
.meta-area-script::-webkit-scrollbar {
  width: 4px;
}
.meta-area-script::-webkit-scrollbar-thumb {
  background-color: #eeeef0;
  border-radius: 17px;
}
/* 계정 설정 */
.member__wrap {
  background: #f8f7ff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 0 4px;
  margin-bottom: 20px;
}
.users.popup_wrap .form_group span {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #1a2032;
  padding: 2px;
}
.member__wrap p {
  text-align: left;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #1a2032;
}
.member__wrap span {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #7b61ff !important;
}
.credit__text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.03em;
  color: #1a2032;
  padding: 6px 2px;
}
.tip__text {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.03em;
  color: #7b61ff;
}
.user_edit__btn {
  display: flex;
  position: relative;
  top: 32px;
  left: 320px;
  width: 84px;
  height: 15px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  color: #666666;
  align-items: center;
}

.user__edit__icon {
  width: 14px;
  height: 14px;
  background-image: url('../images/icons/common/ico-pencil-gray.svg');
}

.user_btn__text {
  font-family: 'Spoqa Han Sans Neo' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  letter-spacing: -0.03em !important;
  color: #666666 !important;
}
.user__phone:focus {
  outline: 1.5px solid #7b61ff;
}

.popup_wrap .form_group {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.form_group input {
  margin-bottom: 20px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  padding: 24px;
}

.popup_wrap {
  padding: 25px;
}

.popup_wrap h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Spoqa Han Sans Neo';
}

.popup_wrap p {
  padding: 15px;
}

/* 07-18 대화형 스크립트 추가 END*/

.audioPlayer {
  display: none;
}

@media (max-height: 1000px) {
  .studio__tool {
    position: static;
  }
  .timeline-area {
    position: static;
    height: auto;
  }
  .studio__main {
    overflow-y: auto;
  }
}

.modal-background {
  background: rgba(0, 0, 0, 0.5);
  width: 200vw;
  margin-left: calc(-50vw + 50%);
  height: 200vh;
  z-index: 9999999;
  position: absolute;
  cursor: pointer;
}

.modal-background video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-110%) translateY(-20%);
  width: 18%;
  height: 20%;
}

.modal-background button:hover {
  color: #7b61ff;
}
.background-video {
  position: relative;
}

.background-video * {
  color: transparent;
}

.hov {
  display: none;
}

.background-video:hover .hov {
  z-index: 999;
  position: absolute;
  right: 1%;
  display: inline;
  transition: 1s ease-in-out;
}

.background-video:hover span {
  color: white;
  font-size: 14px;
}

.background-video:hover .other {
  border-radius: 50px;
  width: 100px;
  padding: 4px 6px;
}

.background__btn__list {
  display: flex;
  position: absolute;
  top: 26px;
  right: 12px;
}

.background__btn__list button {
  padding: 0 4px;
}

.tool__time__box {
  display: flex;
  align-items: center;
}

.tool__time__box p {
  color: #7b61ff;
  font-weight: 1000;
  margin: 0 10px;
}
.img__list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* height: 100vw; */
  position: relative;
}

.edit-new {
  position: fixed;
  bottom: 32%;
}

.style__tab .background {
  height: 64%;
}

.style__tab .deco {
  height: 64%;
}

.ml10 {
  margin-left: 10px !important;
}
