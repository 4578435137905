.landing-top-bg {
  background: linear-gradient(29deg, #000 29.65%, #0d488a 82.99%);
}

.no-control video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

.no-control video::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

.no-control video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
