@tailwind base;
@tailwind components;
@tailwind utilities;

/* pages */
@import './landing.css';
/* components */
@import './components/sketch-picker.css';
@import './components/horizontal-scroll.css';
@import './components/video-control-bar.css';
@import './components/percentage-bar.css';
@import './components/terms.css';

@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Urbanist', 'Spoqa Han Sans Neo', 'Montserrat', 'sans-serif';
}

html {
  overflow-y: auto;
  word-break: keep-all !important;
}

body,
html {
  height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
}

/* for removing arrow button from input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1 !important;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

/* width */
.secondary-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.secondary-scroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.secondary-scroll::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 20px;
}

/* Handle on hover */
.secondary-scroll::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}
