.no-heading h1 {
  display: none;
}

.prose img {
  max-width: 576px;
  border-radius: 12px;
  width: 100%;
  margin: 0 auto;
}

.terms-wrapper dl {
  font-size: 13px;
  margin-bottom: 20px;
}

.no-margin {
  margin: 0px !important;
}

.terms-wrapper dt {
  font-size: 13px;
  float: left;
  margin-left: 5px;
  line-height: 1.7;
}

.terms-wrapper dd {
  font-size: 13px;
  margin-left: 26px;
  line-height: 1.7;
}

.terms-wrapper p {
  font-size: 13px;
  line-height: 1.7;
}

.terms-wrapper table th {
  font-size: 12px;
  line-height: 1.7;
  padding: 5px 5px;
  background-color: #e3e3e4;
  border: 1px solid #454546;
  text-align: center !important;
}

.terms-wrapper table td {
  font-size: 12px;
  line-height: 1.7;
  padding: 5px 5px;
  text-align: center;
  border: 1px solid #454546;
}
