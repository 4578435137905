/* for video player */
.video-control-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-color: #7b61ff;
  border-radius: 50%;
  border: none;
  transition: 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

.video-control-bar::-moz-range-thumb {
  height: 10px;
  width: 10px;
  background-color: #7b61ff;
  border-radius: 50%;
  border: none;
  transition: 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}
