/* for horizontal scrolling menu library */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
}

.react-horizontal-scrolling-menu--scroll-container {
  display: flex;
  column-gap: 20px;
  width: 100%;
  overflow-x: hidden;
}

.react-horizontal-scrolling-menu--item {
  flex: none;
  width: calc(15%);
}

@media not all and (min-width: 1536px) {
  .react-horizontal-scrolling-menu--scroll-container {
    column-gap: 10px;
  }

  .react-horizontal-scrolling-menu--item {
    width: calc(16%);
  }
}

@media not all and (min-width: 1280px) {
  .react-horizontal-scrolling-menu--scroll-container {
    column-gap: 5px;
  }

  .react-horizontal-scrolling-menu--item {
    width: calc(18%);
  }
}
